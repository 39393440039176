import { Form, Input, Radio, Tooltip } from 'antd';
import { LanguageField, ResultScreen } from 'constants/src';
import 'flag-icons/css/flag-icons.min.css';

// This component supports changing both the welcome, scoreThresholdResults
// and afterSubmitResults based on the type prop.
// For welcome we dont support the description,
// which is why this type looks a bit weird
type Props = {
  selectedLanguages: string[];
} & (
  | {
      type: 'welcome';
      section: {
        title: LanguageField;
        buttonText: LanguageField;
      };
      updateSection: (
        field: 'title.en' | 'title.sv' | 'buttonText.en' | 'buttonText.sv',
        value: string | boolean
      ) => void;
    }
  | {
      section: ResultScreen;
      updateSection: (
        field:
          | 'title.en'
          | 'title.sv'
          | 'description.en'
          | 'description.sv'
          | 'buttonText.en'
          | 'buttonText.sv'
          | 'action'
          | 'url',
        value: string | boolean
      ) => void;
      type: 'scoreThreshold' | 'afterSubmit';
    }
);

export const ResultInputs = ({ section, updateSection, selectedLanguages, type }: Props) => {
  return (
    <>
      <Form.Item className={`mt-4 w-full`}>
        <form className="bg-white">
          <div className="mb-4">
            <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
              <div className="flex items-center justify-start gap-2">
                <span>Heading</span>
                <Tooltip title="This information is visible to the respondent">
                  <span className="inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Public
                  </span>
                </Tooltip>
              </div>
              <p className="text-xs font-normal text-gray-600">
                The heading is a gradient colored text that will fill up most of the screen. Put atleast 90% of your
                love at this input.
              </p>
            </label>

            <div className="mt-2">
              {selectedLanguages.includes('en') && (
                <Input
                  id="title"
                  prefix={<span className="flag-icon fi fi-gb rounded-sm" />}
                  showCount
                  className="mb-2"
                  maxLength={100}
                  size="middle"
                  placeholder="Thanks for your answers"
                  value={section?.title.en}
                  onChange={(e) => updateSection('title.en', e.target.value)}
                />
              )}
              {selectedLanguages.includes('sv') && (
                <Input
                  prefix={<span className="flag-icon fi fi-se rounded-sm" />}
                  showCount
                  className="mb-2"
                  maxLength={100}
                  size="middle"
                  placeholder="Tack för dina svar"
                  value={section?.title.sv}
                  onChange={(e) => updateSection('title.sv', e.target.value)}
                />
              )}
            </div>
          </div>
          <div className="mb-0">
            <label htmlFor="buttonText" className="block text-sm font-medium leading-6 text-gray-900">
              <div className="flex items-center justify-start gap-2">
                <span>Button text</span>
                <Tooltip title="This information is visible to the respondent">
                  <span className="inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Public
                  </span>
                </Tooltip>
              </div>
              <p className="text-xs font-normal text-gray-600">
                The button text is the text of the button that is on the screen
              </p>
            </label>

            <div className="mt-2">
              {selectedLanguages.includes('en') && (
                <Input
                  id="buttonText"
                  prefix={<span className="flag-icon fi fi-gb rounded-sm" />}
                  showCount
                  className="mb-2"
                  maxLength={25}
                  size="middle"
                  placeholder="Proceed"
                  value={section?.buttonText.en}
                  onChange={(e) => updateSection('buttonText.en', e.target.value)}
                />
              )}
              {selectedLanguages.includes('sv') && (
                <Input
                  prefix={<span className="flag-icon fi fi-se rounded-sm" />}
                  showCount
                  className="mb-2"
                  maxLength={25}
                  size="middle"
                  placeholder="Fortsätt"
                  value={section?.buttonText.sv}
                  onChange={(e) => updateSection('buttonText.sv', e.target.value)}
                />
              )}
            </div>
          </div>
          {type !== 'welcome' && (
            <div className="mt-4">
              <label htmlFor="buttonText" className="block text-sm font-medium leading-6 text-gray-900">
                Button Action
              </label>

              <div className="align-center mt-2 flex flex-col justify-center">
                <Radio.Group onChange={(e) => updateSection('action', e.target.value)} value={section?.action}>
                  {type === 'scoreThreshold' && (
                    <Tooltip title="Takes the user to the contact information submission form">
                      <Radio.Button value="proceed">Collect info</Radio.Button>
                    </Tooltip>
                  )}
                  <Tooltip title="Restarts the Dialogue">
                    <Radio.Button value="restart">Restart</Radio.Button>
                  </Tooltip>
                  <Tooltip
                    title={`Web URL: Opens up the specified url.
                Embedded: Closes the dialogue widget.`}
                  >
                    <Radio.Button value="close">Close</Radio.Button>
                  </Tooltip>
                  <Tooltip title="Opens up the specified url">
                    <Radio.Button value="url">Go to Url</Radio.Button>
                  </Tooltip>
                </Radio.Group>
                <div className="mt-2">
                  <Input
                    disabled={section?.action !== 'url' && section?.action !== 'close'}
                    showCount
                    addonBefore="https://"
                    className="mb-2"
                    size="middle"
                    placeholder="example.com"
                    value={section?.url}
                    onChange={(e) => updateSection('url', e.target.value.replace('https://', ''))}
                  />
                </div>
              </div>
            </div>
          )}
        </form>
      </Form.Item>
    </>
  );
};
