import { useCallback, useState } from 'react';

import { message } from 'antd';
import { LanguageField } from 'constants/src';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { set } from 'lodash';

import { ResultInputs } from './ResultInputs';
import { useApp } from '../../../AppContext';
import { firestore } from '../../../firebase';
import SelltoButton from '../../../subcomponents/SelltoButton';

export const WelcomeSection = ({ selectedLanguages }: { selectedLanguages: string[] }) => {
  const { assistant, account } = useApp();
  const [saving, setSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [welcomeData, setWelcomeData] = useState<{
    title: LanguageField;
    buttonText: LanguageField;
  }>(
    assistant?.welcome || {
      title: {
        en: '',
        sv: '',
      },
      buttonText: {
        en: '',
        sv: '',
      },
    }
  );

  const saveResults = useCallback(async () => {
    if (!account || !assistant) {
      console.error('No account/assistant');
      return;
    }

    setSaving(true); // Starta sparandets laddningsindikator

    // Använd setTimeout för att skapa en 2 sekunders fördröjning
    setTimeout(async () => {
      try {
        const assistantsCollection = collection(firestore, 'accounts', account.id, 'assistants');

        const assistantDocRef = doc(assistantsCollection, assistant.id);

        // Uppdatera endast afterSubmit-fältet
        await updateDoc(assistantDocRef, { welcome: welcomeData });

        setUnsavedChanges(false); // Återställ tillståndet för osparade ändringar
        message.success('Welcome screen updated successfully');
      } catch (error) {
        message.error('Could not update welcome screen');
        console.error('Error welcome screen:', error);
      } finally {
        setSaving(false); // Stoppa sparandets laddningsindikator
      }
    }, 2000); // 2000 millisekunder = 2 sekunder
  }, [account, assistant, welcomeData]);

  const updateResult = (
    field: 'title.en' | 'title.sv' | 'buttonText.en' | 'buttonText.sv',
    value: string | boolean
  ) => {
    const newWelcome = { ...welcomeData };
    set(newWelcome, field, value);
    setWelcomeData(newWelcome);
    setUnsavedChanges(true); // Ange att det finns osparade ändringar
  };

  return (
    <>
      <div className="mb-10 overflow-hidden rounded-lg border border-gray-200 bg-white">
        <div className="px-4 pt-4">
          <div>
            <div className="mb-6">
              <span className="text-md mb-0 mt-0 font-bold">Welcome screen</span>
              <p className="text-sm text-gray-600">
                This is the first screen your users will see and includes a title message as well as a button text that
                starts their journey in the flow
              </p>
            </div>
          </div>
          <ResultInputs
            section={welcomeData}
            updateSection={updateResult}
            type="welcome"
            selectedLanguages={selectedLanguages}
          />
        </div>
        <div className="flex w-full items-center justify-end gap-4 border-t bg-white px-4 py-3">
          <div className="flex items-center justify-center gap-4">
            {unsavedChanges && <span className="text-xs text-gray-500">Remember to save your changes</span>}
            <SelltoButton onClick={saveResults} disabled={!unsavedChanges} loading={saving} type="black">
              Save
            </SelltoButton>
          </div>
        </div>
      </div>
    </>
  );
};
