import { useEffect, useState } from 'react';

// Inspired by https://stackoverflow.com/a/74618784/2129693

/** Checks wether the device has a virtual keyboard currently active, and updates the state accordingly */
export const useVirtualKeyboard = () => {
  const [hasVirtualKeyboard, setHasVirtualKeyboard] = useState(false);
  useEffect(() => {
    if ('visualViewport' in window) {
      const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;
      window.visualViewport?.addEventListener('resize', function (event) {
        if (
          // @ts-ignore - this works
          (event.target?.height * event.target?.scale) / window.screen.height <
          VIEWPORT_VS_CLIENT_HEIGHT_RATIO
        ) {
          setHasVirtualKeyboard(true);
        } else {
          setHasVirtualKeyboard(false);
        }
      });

      return () => {
        window.visualViewport?.removeEventListener('resize', function (event) {
          if (
            // @ts-ignore - this works
            (event.target?.height * event.target?.scale) / window.screen.height <
            VIEWPORT_VS_CLIENT_HEIGHT_RATIO
          ) {
            setHasVirtualKeyboard(true);
          } else {
            setHasVirtualKeyboard(false);
          }
        });
      };
    }
  }, []);

  return hasVirtualKeyboard;
};
