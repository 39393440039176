import { useEffect, useRef } from 'react';

/**
 * This hook allows you to execute a callback function when a specific key or set of keys is pressed.
 *
 * @param {(event: KeyboardEvent) => void} callback -
 * The callback function to be executed when the specified key(s) is pressed.
 * @param {string | string[]} [key] -
 * The key or keys to listen for. If not provided, the callback will be executed on any key press.
 * @param {{initialDelay?: number; repeatDelay?: number}} [options] -
 * Options to configure the initial delay and repeat delay of the callback function.
 */
export const useKeyDown = (
  callback: (event: KeyboardEvent) => void,
  key?: string | string[],
  options?: { initialDelay?: number; repeatDelay?: number }
) => {
  const mountTime = useRef<number>(Date.now());
  const lastRepeat = useRef<number>(Date.now());
  const handleKeyDown = (event: KeyboardEvent) => {
    const isInArray = Array.isArray(key) && key.includes(event.key);
    const isSingleKey = typeof key === 'string' && event.key === key;
    const allKeys = key === undefined;

    const initialDelay = options?.initialDelay ?? 0;
    const repeatDelay = options?.repeatDelay ?? 0;
    const hasPassedInitialDelay = Date.now() - mountTime.current > initialDelay;
    const hasPassedRepeatDelay = Date.now() - lastRepeat.current > repeatDelay;
    if ((isInArray || isSingleKey || allKeys) && hasPassedInitialDelay && hasPassedRepeatDelay) {
      callback(event);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });
};
