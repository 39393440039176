const OnboardingComplete = () => {
  return (
    <div className="onboarding-complete-container">
      <h2 className="text-2xl font-bold">Integration successful!</h2>
      <p>You can now close this window and return to the app where you started.</p>
      <button
        type="button"
        className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
        onClick={() => window.close()}
      >
        Close Window
      </button>
    </div>
  );
};

export default OnboardingComplete;
