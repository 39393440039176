import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'dialogue.explanation.title',
    defaultMessage: 'Matching explanation',
  },
  helperText: {
    id: 'dialogue.explanation.helperText',
    defaultMessage: 'press Enter',
  },
});
