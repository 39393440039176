import { useEffect, useState } from 'react';

import { Flex, Spinner } from '@chakra-ui/react';
import { Button, message, Result } from 'antd';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, doc, getDoc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';

import { Answer } from './Answer';
import { AppProvider, useApp } from './AppContext'; // Import AppContext
import { AuthRedirectRoute } from './AuthRedirectRoute';
import CompanyMembers from './CompanyMembers';
import CompanySettings from './CompanySettings';
import CreateAccount from './CreateAccount';
import { auth, firestore } from './firebase';
import Layout from './Layout';
import Login from './Login';
import OnboardingComplete from './OnboardingComplete';
import { Customize } from './pages';
import Leads from './pages/leads/Leads';
import Powerups from './Powerups';
import { UserProvider, useUser } from './UserContext';

const RedirectToLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/login');
  }, [navigate]);

  return null;
};

const AppInner = () => {
  const [loading, setLoading] = useState(true);
  const { account, assistant, refresh, error } = useApp();
  const { setUser, user, setLoading: setUserLoading } = useUser();

  // const navigate = useNavigate();

  async function getUserData(uid: string) {
    const userDoc = await getDoc(doc(collection(firestore, 'users'), uid));
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      console.log('No such document!');
      return null;
    }
  }

  useEffect(() => {
    if (account?.id) {
      const appDocRef = doc(firestore, 'accounts', account.id);
      const unsubscribe = onSnapshot(appDocRef, (document) => {
        const updatedApp = document.data();
        if (!account.onboardingComplete && updatedApp?.onboardingComplete) {
          refresh();
          //    navigate('/bots');
        }
      });

      // Städa upp lyssnaren när komponenten avmonteras
      return () => unsubscribe();
    }
  }, [account, refresh]);

  useEffect(() => {
    let unsubscribeSnapshot: Unsubscribe | null = null;

    const unsubscribeAuth = onAuthStateChanged(auth, (newUser) => {
      if (newUser) {
        setLoading(true);
        getUserData(newUser.uid)
          .then((userData) => {
            setUser(userData);
            setLoading(false);
            setUserLoading(false);
            unsubscribeSnapshot = onSnapshot(doc(collection(firestore, 'users'), newUser.uid), (document) => {
              setUser(document.data());
            });
          })
          .catch((err) => {
            setLoading(false);
            console.error(err);
          });
      } else {
        console.log('No user is logged in');
        setUser(null);
        setLoading(false);
        setUserLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeSnapshot) {
        unsubscribeSnapshot();
      }
    };
  }, [account, setUser, setUserLoading]);

  const userLoading = loading && !user;
  // TODO: Fix this. This will not work if its an error that blocks it,
  // it will just load forever
  const accountLoading = user && (account === null || assistant === null);

  if (error) {
    return (
      <Result
        status="error"
        title="An error occured while loading the app."
        subTitle="Please try reloading the page or contact support if the problem persists"
        extra={
          <>
            <Button
              onClick={() => {
                signOut(getAuth())
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((err) => {
                    console.error(err);
                    message.error(`Error: ${err.message}`);
                  });
              }}
            >
              Logout
            </Button>
            <Button onClick={() => window.location.reload()}>Reload page</Button>
          </>
        }
      />
    );
  }

  if (userLoading || accountLoading) {
    return (
      <Flex width="100vw" height="100vh" alignItems="center" justifyContent="center">
        <Spinner
          thickness="3px"
          speed="0.63s"
          emptyColor="#fafafa"
          color="blue-500"
          size="xl"
          width="50px"
          height="50px"
        />
      </Flex>
    );
  }

  const RedirectToDefault = () => {
    const navigate = useNavigate();
    useEffect(() => {
      if (account && assistant) {
        navigate(`/${account.id}/${assistant.id}/leads`);
      }
    }, [navigate]);

    return null;
  };

  return (
    <Router>
      <Routes>
        <Route path="/flow/:accountId/:assistantId" element={<Answer />} />
        <Route
          path="/integrations/pipedrive"
          element={
            <AuthRedirectRoute loggedInLink={`/${account?.id}/${assistant?.id}/powerups`} loggedOutLink="/login" />
          }
        />

        {user ? (
          <>
            {/* Routes för 'flowItems' */}
            <Route path="/:accountId/:assistantId/*" element={<Layout />}>
              <Route path="leads" element={<Leads />} />
              <Route path="edit" element={<Customize />} />
              <Route path="powerups/*" element={<Powerups />} />
              <Route path="onboardingcomplete" element={<OnboardingComplete />} />
              <Route path="*" element={<RedirectToDefault />} />
            </Route>

            <Route path="/:accountId/*" element={<Layout />}>
              <Route path="settings" element={<CompanySettings />} />
              <Route path="members-not-active" element={<CompanyMembers />} />
              <Route path="*" element={<RedirectToDefault />} />
            </Route>

            <Route path="/*" element={<RedirectToDefault />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/*" element={<RedirectToLogin />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

const App = () => {
  return (
    <UserProvider>
      <AppProvider>
        <AppInner />
      </AppProvider>
    </UserProvider>
  );
};

export default App;
