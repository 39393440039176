import { useState } from 'react';

import { Form, Radio, RadioChangeEvent, Switch, message } from 'antd';
import { collection, doc, updateDoc } from 'firebase/firestore';

import { useApp } from '../../../AppContext';
import { firestore } from '../../../firebase';
import SelltoButton from '../../../subcomponents/SelltoButton';

export const DesignSection = () => {
  const { account, assistant, refresh } = useApp();

  const shouldStartWithGradient =
    assistant?.designSettings?.showGradient === undefined ? true : assistant?.designSettings?.showGradient;

  const [showGradient, setShowGradient] = useState<boolean>(shouldStartWithGradient);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saving, setSaving] = useState(false);

  const [theme, setTheme] = useState(assistant?.designSettings?.theme || 'dark');

  const toggleTheme = (e: RadioChangeEvent) => {
    setTheme(e.target.value);
    setUnsavedChanges(true);
  };

  const saveSetup = async () => {
    if (!account || !assistant) {
      console.error('No account/assistant');
      return;
    }

    setSaving(true);

    // We add a timeout here just to make sure that
    // users understands that something happens
    setTimeout(async () => {
      const assistantsCollection = collection(firestore, 'accounts', account.id, 'assistants');

      const assistantDocRef = doc(assistantsCollection, assistant.id);

      try {
        await updateDoc(assistantDocRef, {
          designSettings: {
            showGradient: showGradient,
            theme: theme,
          },
        });
        setUnsavedChanges(false);
        message.success('Assistant updated successfully');
        refresh();
      } catch (error) {
        message.error('Could not update assistant');
      } finally {
        setSaving(false);
      }
    }, 1500); // 1500 millisekunder = 1.5 sekunder
  };

  return (
    <>
      <div className="max-screen-lg mb-10 overflow-hidden rounded-lg border border-gray-200 bg-white">
        <div className="p-4">
          <div className="mb-4">
            <span className="text-md text-md mb-1 mt-0 font-bold ">Design settings</span>
            <p className=" text-xms text-gray-600">These settings affects how your flow looks to your visitors.</p>
          </div>
          <Form.Item className={`mt-4 flex w-full justify-between`}>
            <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
              <div className="flex items-center justify-start gap-2 font-medium">
                <span>Show gradient</span>
              </div>
              <p className="mb-2.5 text-xs font-normal text-gray-600">
                Show the animated gradient background in the flow.
              </p>
            </label>
            <Switch
              checked={showGradient}
              onChange={() => {
                setUnsavedChanges(true);
                setShowGradient(!showGradient);
              }}
              style={{ backgroundColor: showGradient ? '#1890ff' : '#828282' }}
              className="border"
            />
          </Form.Item>
          <Form.Item className={`mt-4 flex w-full justify-between`}>
            <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
              <div className="flex items-center justify-start gap-2 font-medium">
                <span>Select theme</span>
              </div>
              <p className="mb-2.5 text-xs font-normal text-gray-600">Select the theme of your Sellto AI flow</p>
            </label>
            <Radio.Group onChange={toggleTheme} value={theme}>
              <Radio.Button value="light">Light</Radio.Button>
              <Radio.Button value="dark">Dark</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="flex w-full items-center justify-end gap-4 border-t bg-white px-4 py-3">
          {unsavedChanges && <span className="text-xs text-gray-500">Remember to save your changes</span>}
          <SelltoButton onClick={saveSetup} type="black" disabled={!unsavedChanges} loading={saving}>
            Save
          </SelltoButton>
        </div>
      </div>
    </>
  );
};
