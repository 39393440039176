/** Ensures that the promise doesn't resolve until the minimumDelay has passed at least */
export async function minimumDelayedFunction<T>(
  asyncFn: () => Promise<T>,
  minDelay: number,
  { minErrorDelay = 0 } = {}
): Promise<T> {
  const startTime = Date.now();

  try {
    const [result, _delay] = await Promise.all([asyncFn(), new Promise((resolve) => setTimeout(resolve, minDelay))]);
    return result;
  } catch (error) {
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    if (minErrorDelay > timeTaken) {
      await new Promise((resolve) => setTimeout(resolve, minErrorDelay - timeTaken));
    }
    throw error;
  }
}
