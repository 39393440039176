// List of Leads

import { useEffect, useMemo, useState } from 'react';

import { Drawer, Dropdown, Menu, Tabs, Tooltip, message } from 'antd';
import Avvvatars from 'avvvatars-react';
import { Lead } from 'constants/src';
import { Timestamp, collection, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { FaCode, FaLocationCrosshairs } from 'react-icons/fa6';
import { PiCaretDownBold, PiDotsThreeOutlineFill, PiFolderBold, PiUserBold } from 'react-icons/pi';

import 'tippy.js/dist/tippy.css';
import { useApp } from '../../AppContext';
import FaLocationCrosshairsCrossed from '../../assets/FaLocationCrosshairsCrossed';
import HubspotIcon from '../../assets/HubspotIcon';
import PipedriveIcon from '../../assets/PipedriveIcon';
import { firestore } from '../../firebase'; // Din Firestore-instans
import SelltoButton from '../../subcomponents/SelltoButton';
import { useUser } from '../../UserContext';

const { TabPane } = Tabs;

type GroupedLeads = {
  [key: string]: UsableLead[];
};

type UsableLead = Lead & {
  id: string;
  accountId: string;
  createdAt: Timestamp; // Lägg till detta
};

type Tabs = 'collected' | 'dropOut' | 'archived';

const Leads = () => {
  // State
  const [allLeads, setAllLeads] = useState<UsableLead[]>([]);
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);
  const [selectedLead, setSelectedLead] = useState<UsableLead | null>(null);
  const [isLeadDrawerOpen, setIsLeadDrawerOpen] = useState(false);
  const [displayedLeadsCount, setDisplayedLeadsCount] = useState(5);
  const [groupedLeads, setGroupedLeads] = useState<Record<string, UsableLead[]>>({});
  const [selectedTab, setSelectedTab] = useState<Tabs>('collected');

  // Clear selectedLeads when changing tabs,
  // since otherwise we will have leads that are selected that we cant see
  useEffect(() => {
    setSelectedLeads([]);
  }, [selectedTab]);

  // Hooks
  const user = useUser();
  const { account, assistant } = useApp();

  useEffect(() => {
    if (account && assistant) {
      const leadsRef = collection(firestore, 'accounts', account.id, 'assistants', assistant.id, 'leads');

      const unsubscribe = onSnapshot(leadsRef, (snapshot) => {
        const newLeads: UsableLead[] = snapshot.docs.map((document) => {
          const data = document.data() as Omit<Lead, 'id'>;
          return {
            ...data,
            id: document.id,
            accountId: account.id,
            // We default to 2022-01-01 just so app doesnt crash if for some reason we dont have a date
            createdAt: (data.createdAt as Timestamp) || Timestamp.fromDate(new Date('2022-01-01')), // Behåll som Timestamp
          };
        });

        setAllLeads(newLeads);
      });

      return () => unsubscribe();
    }
  }, [user, assistant, account]);

  const filteredLeads = useMemo(() => {
    let filteredL: UsableLead[] = [];
    if (selectedTab === 'collected') {
      filteredL = allLeads.filter((lead) => !lead.archived && lead.email);
    }
    if (selectedTab === 'dropOut') {
      filteredL = allLeads.filter((lead) => !lead.archived && !lead.email);
    }
    if (selectedTab === 'archived') {
      filteredL = allLeads.filter((lead) => lead.archived);
    }
    return filteredL.sort((a, b) => {
      return b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime();
    });
  }, [allLeads, selectedTab]);

  useEffect(() => {
    const groupByMonth = filteredLeads.reduce((acc: GroupedLeads, lead) => {
      const date = lead.createdAt.toDate(); // Konvertera Timestamp till Date
      const monthYear = date.toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric',
      });

      // Gruppera leads efter månad och år
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(lead);

      return acc;
    }, {} as GroupedLeads);

    setGroupedLeads(groupByMonth);
  }, [filteredLeads]);

  // Functions

  // Score badge
  function getBadgeStyle(score: number | undefined | null) {
    if (score === undefined || score === null) {
      return 'bg-gray-100 text-gray-500'; // Grå stil för undefined eller null
    } else if (score <= 20) {
      return 'bg-orange-50 text-orange-400 border border-orange-100';
    } else if (score <= 60) {
      return 'bg-blue-50 text-blue-400 border border-blue-100';
    } else {
      return 'bg-emerald-50 text-emerald-500 border border-emerald-100';
    }
  }

  const handleSelectOne = (e: React.ChangeEvent<HTMLInputElement>) => {
    const leadId = e.target.dataset.leadid;
    if (!leadId) {
      console.error('No leadId');
      return;
    }
    if (selectedLeads.includes(leadId)) {
      setSelectedLeads(selectedLeads.filter((id) => id !== leadId));
    } else {
      setSelectedLeads([...selectedLeads, leadId]);
    }
  };

  const handleSelectAll = () => {
    if (selectedLeads.length === filteredLeads.length) {
      // Om alla leads redan är markerade, avmarkera dem
      setSelectedLeads([]);
    } else {
      // Annars, markera alla leads
      setSelectedLeads(filteredLeads.map((lead) => lead.id));
    }
  };

  const handlePushToPipedrive = async (leadId: string) => {
    if (!assistant || !account) {
      console.error('No assistant or account');
      return;
    }
    try {
      // Bygg URL:en för att trigga Pipedrive-funktionen
      const url = `${process.env.REACT_APP_API_URL}/pipedriveLeadSync`; // Byt ut detta mot din riktiga URL

      // Skicka en POST-förfrågan med appId och leadId som kropp
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          accountId: account.id,
          assistantId: assistant.id,
          leadId,
        }),
      });

      // Kolla statuskoden för svaret
      if (!response.ok) {
        // Om statuskoden inte är OK (2xx), kasta ett fel
        throw new Error(`Server responded with status code ${response.status}`);
      }

      const lead = allLeads.find((l) => l.id === leadId);
      if (lead) {
        message.success(`Lead: ${lead.name} successfully pushed to Pipedrive`);
      }
    } catch (error) {
      message.error('Could not pus  lead to Pipedrive');
      console.error(error);
      // Visa felmeddelande eller hantera felet på något annat sätt...
    }
  };

  const handlePushToHubspot = async (leadId: string) => {
    if (!assistant || !account) {
      console.error('No assistant or account');
      return;
    }
    try {
      // Bygg URL:en för att trigga Pipedrive-funktionen
      const url = `${process.env.REACT_APP_API_URL}/hubspotLeadSync`; // Byt ut detta mot din riktiga URL

      // Skicka en POST-förfrågan med appId och leadId som kropp
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          accountId: account.id,
          assistantId: assistant.id,
          leadId,
        }),
      });

      // Kolla statuskoden för svaret
      if (!response.ok) {
        // Om statuskoden inte är OK (2xx), kasta ett fel
        throw new Error(`Server responded with status code ${response.status}`);
      }

      const lead = allLeads.find((l) => l.id === leadId);
      if (lead) {
        message.success(`Lead: ${lead.name} successfully pushed to Hubspot`);
      }
    } catch (error) {
      console.error(error);
      message.error('Could not push lead to Hubspot');

      // Visa felmeddelande eller hantera felet på något annat sätt...
    }
  };

  const getLeadRefById = (leadId: string) => {
    if (!assistant || !account) {
      console.error('No assistant or account');
      return;
    }
    return doc(firestore, 'accounts', account.id, 'assistants', assistant.id, 'leads', leadId);
  };

  const handleArchiveLeadToggle = async (leadId: string) => {
    try {
      const leadRef = getLeadRefById(leadId);
      if (!leadRef) {
        return;
      }

      await updateDoc(leadRef, {
        archived: selectedTab === 'archived' ? false : true,
      });

      message.success(`Lead: ${leadId} successfully ${selectedTab === 'archived' ? 'unarchived' : 'archived'}`);
      // Du kan välja att uppdatera din lokal state här om det behövs
    } catch (error) {
      console.error(error);
      message.error(`Could not ${selectedTab === 'archived' ? 'unarchive' : 'archive'} lead`);
    }
  };

  // Correctly closing the handleArchiveLead function
  const handleArchiveSelectedLeadsToggle = async () => {
    try {
      for (const leadId of selectedLeads) {
        const leadRef = getLeadRefById(leadId);
        if (!leadRef) {
          continue;
        }

        await updateDoc(leadRef, {
          archived: selectedTab === 'archived' ? false : true,
        });
      }
      message.success(
        `${selectedLeads.length} leads successfully ${selectedTab === 'archived' ? 'unarchived' : 'archived'}`
      );
      setSelectedLeads([]); // Rensa urvalet efter arkivering
    } catch (error) {
      console.error(error);
      message.error(`Could not ${selectedTab === 'archived' ? 'unarchived' : 'archived'} selected leads`);
    }
  };

  const handleLeadClick = (e: React.MouseEvent<HTMLButtonElement>, lead: UsableLead) => {
    if ((e.target as HTMLInputElement).type === 'checkbox') {
      return;
    }
    setSelectedLead(lead);
    setIsLeadDrawerOpen(true);
  };

  const closeLeadDrawer = () => {
    setIsLeadDrawerOpen(false);
  };

  const handleLoadMore = () => {
    setDisplayedLeadsCount((prevCount) => prevCount + 5);
  };

  const flatLeads = Object.keys(groupedLeads)
    .sort((a, b) => {
      const dateA = new Date(`1 ${a}`);
      const dateB = new Date(`1 ${b}`);
      return dateB.getTime() - dateA.getTime();
    })
    .reduce((acc: (UsableLead & { monthYear: string })[], monthYear) => {
      const leadsForMonth = groupedLeads[monthYear];
      return [...acc, ...leadsForMonth.map((lead) => ({ ...lead, monthYear }))];
    }, []);

  const hasMoreLeads = displayedLeadsCount < filteredLeads.length;

  function formatDate(date: Date): string {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Sätt dagens tid till midnatt

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1); // Sätt gårdagens tid till midnatt

    const dateToCompare = new Date(date);
    dateToCompare.setHours(0, 0, 0, 0); // Sätt input-datumets tid till midnatt

    const differenceInTime = today.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    if (dateToCompare.getTime() === today.getTime()) {
      return 'Today';
    } else if (dateToCompare.getTime() === yesterday.getTime()) {
      return 'Yesterday';
    } else if (differenceInDays < 7) {
      return `${differenceInDays} days ago`;
    } else if (differenceInDays < 28) {
      return `${Math.ceil(differenceInDays / 7)} weeks ago`;
    } else {
      return dateToCompare.toISOString().split('T')[0].replace(/-/g, '/');
    }
  }

  return (
    <div className="mx-auto min-h-full max-w-6xl">
      <div id="LeadsHeader" className="mb-4 flex items-center justify-between">
        <div className="text-2xl font-bold">Leads</div>
        <div></div>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={handleSelectAll}>
                {selectedLeads.length === filteredLeads.length ? 'Unselect all leads' : 'Select all leads'}
              </Menu.Item>
              <Menu.Item
                key="3"
                onClick={() => handleArchiveSelectedLeadsToggle()}
                disabled={selectedLeads.length === 0}
              >
                {selectedTab === 'archived' ? 'Unarchive selected' : 'Archive selected'}
              </Menu.Item>
            </Menu>
          }
        >
          <SelltoButton icon={<PiCaretDownBold size={16} />}>Select</SelltoButton>
        </Dropdown>
      </div>
      <Drawer
        title="Lead Details"
        placement="right"
        closable={true}
        onClose={closeLeadDrawer}
        open={isLeadDrawerOpen}
        width={400}
      >
        {selectedLead && (
          <div className="flex h-full flex-col justify-between">
            <div className="flex flex-col items-start justify-center bg-white p-4 ">
              <div className="mb-8 flex w-full items-center justify-between">
                <div className="">
                  <h2 className="text-lg font-semibold text-gray-900 ">{selectedLead.name}</h2>
                  <p className="text-sm text-gray-500 ">{selectedLead.companyName}</p>
                </div>
                <Tooltip title={`Lead-ID: ${selectedLead.id}`}>
                  <div className="flex items-center justify-center rounded-full bg-gray-100 p-2">
                    <FaCode size={16} className=" text-gray-700" />
                  </div>
                </Tooltip>
              </div>
              <div className="mb-4">
                <h3 className="text-sm font-semibold text-gray-900 ">Contact Information</h3>
                <div className="mt-2 space-y-2">
                  <p className="text-sm text-gray-500 ">
                    <svg
                      className=" mr-2 inline-block h-5 w-5"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect height="16" rx="2" width="20" x="2" y="4" />
                      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                    </svg>
                    {selectedLead.email ? selectedLead.email : 'N/A'}
                  </p>
                </div>
                <div className="mt-2 flex flex-row items-center justify-start gap-2">
                  <p className="text-sm text-gray-500 ">
                    <svg
                      className=" mr-2 inline-block h-5 w-5"
                      fill="none"
                      height="24"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                    </svg>
                    {selectedLead.phone ? selectedLead.phone : 'N/A'}
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <h3 className="text-md font-semibold text-gray-900 ">Score</h3>
                <p className="mt-2 flex items-center justify-center rounded-full border border-gray-200 bg-gray-100 px-4 py-0.5 text-sm font-bold text-gray-500 ">
                  {selectedLead.score ? selectedLead.score : 'N/A'}
                </p>
              </div>
              <div>
                <h3 className="font-md mb-2 font-semibold">AI Sales points</h3>
                <div className="mb-4 rounded-xl bg-gray-100 p-4">
                  <p className="mt-0 flex text-sm text-gray-600 ">
                    {selectedLead.salesPoint ? selectedLead.salesPoint : 'N/A'}
                  </p>
                </div>
              </div>
              <div>
                <h3 className="font-md mb-2 font-semibold">Dialogue</h3>
                <div className="mb-4 rounded-xl bg-gray-100 p-4">
                  <ul className="list-none pl-0">
                    {selectedLead.customAnswers?.map((item, index) => (
                      <li key={index} className="mt-2 text-sm text-gray-600">
                        <strong>
                          {index + 1}. {item.question}
                        </strong>
                        : {item.answer}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div>
                <h3 className="font-md mb-2 font-semibold">Follow-up</h3>
                <div className="mb-4 rounded-xl bg-gray-100 p-4">
                  <span className="text-sm font-semibold text-gray-900 ">Question</span>
                  <p className="mb-2 mt-0 text-sm text-gray-600 ">
                    {selectedLead.followUpQuestion ? selectedLead.followUpQuestion.title : 'N/A'}
                  </p>
                  <h3 className="text-sm font-semibold text-gray-900 ">Follow-up Answer</h3>
                  <p className="mt-0 text-sm text-gray-600 ">
                    {selectedLead.followUpAnswer ? selectedLead.followUpAnswer : 'N/A'}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 px-6 pb-4 pt-4">
              {assistant?.powerups.hubspot && selectedTab === 'collected' && (
                <button
                  type="button"
                  className="w-full rounded bg-white px-2 py-1 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => handlePushToHubspot(selectedLead.id)}
                >
                  {selectedLead.pushedTo?.hubspot ? 'Re-push to Hubspot' : 'Push to Hubspot'}
                </button>
              )}
              {assistant?.powerups.pipedrive && selectedTab === 'collected' && (
                <button
                  type="button"
                  className="w-full rounded bg-white px-2 py-1 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => handlePushToPipedrive(selectedLead.id)}
                >
                  {selectedLead.pushedTo?.pipedrive ? 'Re-push to Pipedrive' : 'Push to Pipedrive'}
                </button>
              )}
              <button
                type="button"
                className="inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm transition-all hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => handleArchiveLeadToggle(selectedLead.id)}
              >
                <PiFolderBold className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                {selectedTab === 'archived' ? 'Unarchive Lead' : 'Archive Lead'}
              </button>
            </div>
          </div>
        )}
      </Drawer>

      <main>
        <Tabs
          defaultActiveKey="collected"
          size="small"
          activeKey={selectedTab}
          onChange={(v) => setSelectedTab(v as Tabs)}
        >
          <TabPane tab="Collected" key="collected"></TabPane>
          <TabPane tab="Drop-outs" key="dropOut"></TabPane>
          <TabPane tab="Archived" key="archived"></TabPane>
        </Tabs>
      </main>
      <div className="relative mt-2 pt-6 ring-opacity-5">
        {(() => {
          let lastMonthYear: string | null = null;
          let leadsGroup: JSX.Element[] = [];

          const leadsGroups: JSX.Element[] = [];

          flatLeads.slice(0, displayedLeadsCount).forEach((lead) => {
            const isNewMonth = lead.monthYear !== lastMonthYear;
            if (isNewMonth && lastMonthYear !== null) {
              // Push the previous group of leads
              leadsGroups.push(
                <div className="mb-12 overflow-hidden rounded-md border border-gray-100 " key={lastMonthYear}>
                  {leadsGroup}
                </div>
              );
              // Start a new group of leads
              leadsGroup = [];
            }
            lastMonthYear = lead.monthYear;
            leadsGroup.push(
              <div key={lead.id}>
                {isNewMonth && <h2 className="bottom absolute -mt-7 text-sm">{lead.monthYear}</h2>}
                <div id="tableContents" className=" bg-white">
                  <div
                    id="itemwrap"
                    key={lead.id}
                    className="relative w-full cursor-pointer border-b border-gray-100 shadow-none transition-all hover:bg-gray-50"
                  >
                    <button
                      type="button"
                      className="absolute h-full w-full"
                      onClick={(e) => handleLeadClick(e, lead)}
                    />
                    <div id="table-item" className="clickable-row flex w-full ">
                      <div className="flex items-center justify-center whitespace-nowrap px-4 py-3.5 text-sm font-medium text-gray-900 sm:pl-6">
                        <input
                          type="checkbox"
                          className="z-10 h-5 w-5 cursor-pointer rounded border-gray-200 text-blue-600"
                          data-leadid={lead.id}
                          checked={selectedLeads.includes(lead.id)}
                          onChange={handleSelectOne}
                        />
                      </div>
                      <div className="flex w-1/3 py-3.5  pr-3 text-sm font-medium text-gray-900 sm:flex sm:items-center ">
                        <div className="sm:flex sm:items-center">
                          <div className="flex items-center justify-center gap-4">
                            <div className="ml-1 mr-1">
                              {lead.name ? (
                                <Avvvatars style="character" value={lead.name} size={40} />
                              ) : (
                                <div
                                  className="flex items-center justify-center rounded-full bg-gray-200"
                                  style={{ width: 40, height: 40 }}
                                >
                                  <PiUserBold size={20} />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ml-3">
                          <div className="text-md font-semibold text-black">{lead.name}</div>
                          <div className="text-xms font-normal leading-tight text-gray-600">{lead.email}</div>
                        </div>
                      </div>
                      <div className="flex w-1/5 items-center justify-start gap-2 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500">
                        {lead.companyName ? (
                          <FaLocationCrosshairs style={{ fontSize: 16, color: 'dimGray' }} />
                        ) : (
                          <FaLocationCrosshairsCrossed
                            style={{ fontSize: 20 }}
                            className="text-gray-500"
                            fill="dimGray"
                          />
                        )}
                        <div className="flex items-center justify-between gap-2 rounded-md bg-neutral-50 px-1.5 py-0.5 text-sm font-medium">
                          <span>{lead.companyName || 'Unknown'}</span>
                        </div>
                      </div>
                      <div className="whitespace-now flex w-1/6 items-center justify-center px-3 py-3.5 text-sm text-gray-500">
                        <span
                          className={`inline-flex w-[40px] items-center justify-center rounded-md text-xs font-bold ${getBadgeStyle(
                            lead.score
                          )}`}
                        >
                          <span className="text-sm">
                            {lead.score !== undefined && lead.score !== null ? lead.score : 'N/A'}
                          </span>
                        </span>
                      </div>

                      <div className="z-50 flex w-1/6 items-center justify-end gap-2 whitespace-nowrap py-3 pr-8 text-sm text-gray-500">
                        <>
                          <Tooltip
                            title={
                              !assistant?.powerups.pipedrive
                                ? 'No Pipedrive integration'
                                : lead.pushedTo?.pipedrive
                                  ? 'Pushed to Pipedrive'
                                  : 'Not pushed to Pipedrive'
                            }
                            color="gray"
                            placement="top"
                          >
                            <div className="flex h-[30px] w-[30px] items-center justify-center rounded-xl bg-gray-50 p-1">
                              <PipedriveIcon
                                className={lead.pushedTo?.pipedrive ? 'opacity-100' : 'opacity-20'}
                                style={{
                                  fontSize: 14,
                                  filter: lead.pushedTo?.pipedrive ? 'none' : 'grayscale(0%)',
                                }}
                              />
                            </div>
                          </Tooltip>
                          <Tooltip
                            title={
                              !assistant?.powerups.hubspot
                                ? 'No HubSpot integration'
                                : lead.pushedTo?.hubspot
                                  ? 'Pushed to HubSpot'
                                  : 'Not pushed to HubSpot'
                            }
                            color="gray"
                            placement="top"
                          >
                            <div className="flex h-[30px] w-[30px] items-center justify-center rounded-xl bg-gray-50 p-1">
                              <HubspotIcon
                                className={lead.pushedTo?.hubspot ? 'opacity-100' : 'opacity-40'}
                                style={{
                                  fontSize: 14,
                                  filter: lead.pushedTo?.hubspot ? 'none' : 'grayscale(100%)',
                                }}
                              />
                            </div>
                          </Tooltip>
                        </>
                      </div>
                      <div className="z-50 flex w-1/6 items-center justify-end gap-2 whitespace-nowrap py-3 pr-8 text-sm text-gray-500">
                        <span>{formatDate(lead.createdAt.toDate())}</span>
                      </div>
                      <div className="flex w-1/12 items-center justify-end whitespace-nowrap py-3 pr-8  text-sm text-gray-500">
                        <Dropdown
                          overlay={
                            <Menu>
                              {assistant?.powerups.hubspot && selectedTab === 'collected' && (
                                <Menu.Item
                                  onClick={() => {
                                    handlePushToHubspot(lead.id);
                                  }}
                                >
                                  {lead.pushedTo?.hubspot ? 'Re-push to Hubspot' : 'Push to Hubspot'}
                                </Menu.Item>
                              )}
                              {assistant?.powerups.pipedrive && selectedTab === 'collected' && (
                                <Menu.Item
                                  onClick={() => {
                                    handlePushToPipedrive(lead.id);
                                  }}
                                >
                                  {lead.pushedTo?.pipedrive ? 'Re-push to Pipedrive' : 'Push to Pipedrive'}
                                </Menu.Item>
                              )}
                              {!assistant?.powerups.pipedrive &&
                                !assistant?.powerups.hubspot &&
                                selectedTab === 'collected' && <Menu.Item disabled>No CRM integration</Menu.Item>}
                              <Menu.Item
                                onClick={() => {
                                  handleArchiveLeadToggle(lead.id);
                                }}
                              >
                                {selectedTab === 'archived' ? 'Unarchive Lead' : 'Archive Lead'}
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <button
                            type="button"
                            className="z-10 flex items-center justify-center text-2xl"
                            style={{ cursor: 'pointer' }}
                          >
                            <svg
                              className="flex h-5 w-5 items-center justify-center"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                            >
                              <PiDotsThreeOutlineFill />
                            </svg>
                          </button>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          leadsGroups.push(
            <div className="mb-6 overflow-hidden rounded-lg border border-gray-100" key={lastMonthYear}>
              {leadsGroup}
            </div>
          );
          return leadsGroups;
        })()}
      </div>
      <div className="flex flex-col items-center justify-center p-4">
        {hasMoreLeads ? (
          <SelltoButton size="large" onClick={handleLoadMore} disabled={!hasMoreLeads} type="black">
            Show More
          </SelltoButton>
        ) : (
          <SelltoButton size="large" disabled={!hasMoreLeads} type="black">
            No more leads
          </SelltoButton>
        )}
        <span className="mt-2 text-xs text-gray-500">
          {hasMoreLeads
            ? `Showing ${Math.min(displayedLeadsCount, filteredLeads.length)} of ${filteredLeads.length}`
            : `Showing all ${filteredLeads.length} leads`}
        </span>
      </div>
    </div>
  );
};
// End of added new codde

export default Leads;
