import { useMemo, useState } from 'react';

import { Checkbox, Dropdown, Menu, Switch, Tooltip, message } from 'antd';
import { Assistant } from 'constants/src';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { PiCaretDownBold, PiLinkBold } from 'react-icons/pi';

import { AfterSubmitSection } from './components/AfterSubmitSection';
import { AssistantInformationSection } from './components/AssistantInformationSection';
import { DesignSection } from './components/DesignSection';
import { QuestionsSection } from './components/QuestionsSection';
import { ScoreResultSection } from './components/ScoreResultSection';
import { TriggerSection } from './components/TriggerSection';
import { WelcomeSection } from './components/WelcomeSection';
import { useApp } from '../../AppContext';
import { firestore } from '../../firebase';
import SelltoButton from '../../subcomponents/SelltoButton';

export const Customize = () => {
  // State
  const [loading, setLoading] = useState(false);
  const { account, assistant, refresh } = useApp();
  const [selectedLanguages, setSelectedLanguages] = useState(assistant?.languages || ['en']);
  const [aiEnabled, setAiEnabled] = useState<boolean>(assistant?.aiEnabled || false);
  const [collectContact, setCollectContact] = useState<boolean>(assistant?.collectContact || false);

  // Derived state
  const enabled = useMemo(() => assistant?.enabled, [assistant]);

  // Functions
  const copyToClipboard = () => {
    if (!account || !assistant || !navigator.clipboard) {
      message.error('Could not copy to clipboard');
      return;
    }
    navigator.clipboard.writeText(`${process.env.REACT_APP_WEB_URL}/flow/${account.id}/${assistant.id}`);
    message.success('Link copied to clipboard');
  };

  const changeEnabled = async (checked: boolean) => {
    if (!account || !assistant) {
      console.error('No account/assistant');
      return;
    }
    setLoading(true);

    const assistantsCollection = collection(firestore, 'accounts', account.id, 'assistants');

    const assistantDocRef = doc(assistantsCollection, assistant.id);

    const updatedAssistant: Pick<Assistant, 'enabled'> = {
      enabled: checked,
    };

    try {
      await updateDoc(assistantDocRef, updatedAssistant);
      message.success(checked ? 'Assistant is now live' : 'Assistant is now offline');
      await refresh();
    } catch (error) {
      message.error('Could not update assistant');
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageSelect = async (lang: string) => {
    if (!account || !assistant) {
      console.error('No account or assistant found');
      message.error('Failed to update languages. No account or assistant found.');
      return;
    }
    let newLanguages;
    if (selectedLanguages.includes(lang)) {
      newLanguages = selectedLanguages.filter((l) => l !== lang);
    } else {
      newLanguages = [...selectedLanguages, lang];
    }

    setSelectedLanguages(newLanguages);

    // Uppdaterar språk i databasen
    try {
      const assistantsCollection = collection(firestore, 'accounts', account.id, 'assistants');
      const assistantDocRef = doc(assistantsCollection, assistant.id);
      await updateDoc(assistantDocRef, { languages: newLanguages });

      message.success('Languages updated and published.');
    } catch (error) {
      console.error('Error updating languages:', error);
      message.error('Failed to update languages.');
    }
  };

  const languageMenu = (
    <Menu>
      <Menu.Item key="en" disabled>
        <Checkbox checked disabled>
          English (Mandatory)
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="sv">
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleLanguageSelect('sv');
          }}
          className="flex w-full items-center" // Tailwind CSS-klasser
        >
          <Checkbox checked={selectedLanguages.includes('sv')} />
          <span className="ml-2">Svenska</span> {/* Margin-left med Tailwind */}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="mx-auto min-h-full w-full max-w-4xl">
      <div className="min-h-full w-full">
        <div id="CustomizeHeader" className="mb-6 flex items-center justify-between">
          <div className="text-2xl font-bold">Configuration</div>
          <div className="flex flex-row items-center justify-center gap-2">
            <div className="">
              <Tooltip className="flex items-center justify-center" title="Enable/Disable the Share URL">
                <div className="flex items-center justify-center">
                  <Switch
                    checked={enabled}
                    onChange={changeEnabled}
                    loading={loading}
                    size="small"
                    className="border"
                  />
                </div>
                <div className="text-center text-sm"></div>
              </Tooltip>
            </div>
            <div>
              <Dropdown overlay={languageMenu} trigger={['click']}>
                <SelltoButton icon={<PiCaretDownBold size={16} />}>Select languages</SelltoButton>
              </Dropdown>
            </div>

            <SelltoButton iconPosition="left" icon={<PiLinkBold size={15} />} type="black" onClick={copyToClipboard}>
              <span>Copy flow URL</span>
            </SelltoButton>
          </div>
        </div>
      </div>
      <AssistantInformationSection
        aiEnabled={aiEnabled}
        setAiEnabled={setAiEnabled}
        collectContact={collectContact}
        setCollectContact={setCollectContact}
      />
      <DesignSection />
      <TriggerSection />
      <WelcomeSection selectedLanguages={selectedLanguages} />
      <QuestionsSection selectedLanguages={selectedLanguages} aiEnabled={aiEnabled} />
      {aiEnabled && <ScoreResultSection selectedLanguages={selectedLanguages} />}
      <AfterSubmitSection selectedLanguages={selectedLanguages} />
    </div>
  );
};
