import { useCallback, useMemo, useRef, useState } from 'react';

import { InformationCircleIcon } from '@heroicons/react/outline';
import { Tooltip } from 'antd';
import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';
import TextareaAutosize from 'react-textarea-autosize';

import { DialogueButton } from './DialogueButton';
import { useTheme } from '../../context/ThemeContext';
import { useKeyDown } from '../../utils/useKeyDown';
import { getTitleTextSize } from '../utils/textSizing';

import { messages } from './QuestionScreen.text';

import './QuestionScreen.scss';

type QuestionScreenProps = {
  title: string;
  description?: string;
  onClick: () => void;
  value: string;
  onChange: (e: string) => void;
  validator?: (text: string) => string | false | undefined;
};

export const QuestionScreen = ({ title, description, onClick, value, onChange, validator }: QuestionScreenProps) => {
  const theme = useTheme();
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const showInput = focused || value?.length > 0;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { formatMessage } = useIntl();

  const textSize = useMemo(() => getTitleTextSize(title.length, showInput), [showInput, title]);

  const validatedClick = useCallback(() => {
    const errMsg = validator?.(value);
    if (errMsg) {
      setError(errMsg);
      return;
    }
    setError(undefined);
    onClick();
  }, [validator, value, onClick]);

  const firstKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        inputRef.current?.blur();
        return;
      }
      if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
        validatedClick();
        return;
      }
      if (
        event.key === 'Shift' ||
        event.key === 'Control' ||
        event.key === 'Alt' ||
        event.key === 'Meta' ||
        event.key === 'Tab'
      ) {
        return;
      }
      inputRef.current?.focus();
    },
    [validatedClick]
  );

  const realOnChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      // Remove all \n from the input, since we hijack enter key for submit
      const newValue = e.target.value.replace(/\n/g, '');

      // This is so we clear the error if its no longer an error input
      if (error) {
        const errMsg = validator?.(newValue);
        setError(errMsg || undefined);
      }
      onChange(newValue);
    },
    [error, onChange, validator]
  );

  useKeyDown(firstKeyDown, undefined, { initialDelay: 100 });
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.3 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.7 }}
      transition={{ duration: 0.3 }}
      className="relative flex h-full w-full flex-col justify-center"
      onTouchMove={(e) => {
        e.preventDefault();
      }}
    >
      <div
        className="inline-flex flex-col items-center justify-start gap-5"
        onTouchMove={(e) => {
          e.preventDefault();
        }}
      >
        {description && (
          <div className={`relative ${showInput ? 'h-8 w-8' : 'h-10 w-10'}`}>
            {/* TODO: Make this a button instead?
            And maybe make it able to be keyboard activated as well? */}
            <Tooltip
              title={description}
              placement="top"
              zIndex={100000}
              // We cant use css variables here,
              // since the tooltip is rendered outside of the react tree
              color={theme.colors.onBackground}
              overlayInnerStyle={{ color: theme.colors.background }}
            >
              <InformationCircleIcon
                className={`transition-all hover:opacity-50 hover:duration-300 hover:ease-in-out ${
                  showInput ? 'h-8 w-8' : 'h-10 w-10'
                }`}
                style={showInput ? { color: theme.colors.onSurface } : { color: theme.colors.onBackground }}
                aria-hidden="true"
              />
            </Tooltip>
          </div>
        )}
        <div
          className={`${textSize} text-center font-normal transition-all duration-300 sm:mb-4 ${
            showInput ? `text-on-surface mx-10 max-w-2xl leading-5` : `leading-20 text-on-background mx-8 max-w-4xl`
          }`}
          style={{ lineHeight: '130%' }}
          onTouchMove={(e) => {
            e.preventDefault();
          }}
        >
          {title}
        </div>

        <div
          className="flex max-w-full flex-col items-center justify-start gap-5"
          onTouchMove={(e) => {
            e.preventDefault();
          }}
        >
          <div
            onClick={() => inputRef.current?.focus()}
            className={`bg-surface relative flex min-h-[86px] max-w-full cursor-text flex-row items-center justify-center overflow-hidden rounded-2xl bg-opacity-10 px-6 py-4 transition-all duration-300 hover:bg-opacity-20 sm:min-h-[112px] md:px-14 md:py-8 ${
              showInput ? 'w-80 sm:w-[500px] md:w-[700px] lg:w-[963px]' : 'w-80 sm:w-96'
            } ${error ? 'ring-2 ring-red-500' : 'ring-0'}`}
            onTouchMove={(e) => {
              e.preventDefault();
            }}
          >
            <TextareaAutosize
              ref={inputRef}
              value={value}
              onChange={realOnChange}
              onFocus={() => {
                // Scroll to this element
                inputRef.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                  inline: 'center',
                });
                setFocused(true);
              }}
              onBlur={() => setFocused(false)}
              maxRows={5}
              maxLength={500}
              className={`scrollbar-hide align-center text-on-surface z-10 m-0 flex w-full resize-none justify-center overflow-y-auto border-none bg-transparent p-0 text-center text-2xl font-medium outline-none transition-all duration-300 ease-out focus:border-none focus:outline-none focus:ring-0 sm:text-3xl md:text-5xl lg:text-6xl`}
              spellCheck={false}
              style={{ lineHeight: '120%' }}
              // @ts-ignore this works, but typescript doesn't like it.
              // Its used so mobile keyboard shows a send
              // icon instead of a "newLine" icon
              enterKeyHint="send"
            />
            {!showInput && (
              <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
                <div className="animate-blink bg-on-surface relative h-16 w-0.5"></div>
              </div>
            )}
          </div>
          {error && <div className="text-error text-center text-sm font-bold">{error}</div>}
          <DialogueButton
            buttonText={showInput ? formatMessage(messages.buttonText) : undefined}
            onClick={validatedClick}
            helperText={
              showInput ? formatMessage(messages.helperTextContinue) : formatMessage(messages.helperTextAnykey)
            }
          />
        </div>
      </div>
    </motion.div>
  );
};
