import { useEffect, useState } from 'react';

import { Dropdown, Menu, message } from 'antd';
import Avvvatars from 'avvvatars-react';
import { getAuth, signOut } from 'firebase/auth';
import { PiDotsThreeOutlineFill } from 'react-icons/pi';

import UpdateUserModal from './UpdateUserModal'; // Import UpdateUserModal

const TopNav = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const openUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  useEffect(() => {
    const fetchUserDocId = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user?.email) {
        setEmail(user.email);
      }
    };
    fetchUserDocId();
  }, []);

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      message.success('Successfully logged out');
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        message.error(`Error: ${error.message}`);
        return;
      }
      message.error(`Error: Unknown error occurred`);
    }
  };

  const userNavigation = (
    <Menu>
      <Menu.Item key="1" onClick={openUpdateModal}>
        Settings
      </Menu.Item>
      <Menu.Item key="2" onClick={handleLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <UpdateUserModal isOpen={isUpdateModalOpen} closeModal={closeUpdateModal} />
      <div className="w-full items-center  text-xl font-semibold transition-all duration-200">
        <div className="flex w-full items-center justify-between overflow-hidden rounded-[8px] border border-neutral-200 p-1.5 pr-1">
          <Dropdown overlay={userNavigation} trigger={['click']} overlayStyle={{ cursor: 'pointer' }}>
            <div className="flex w-full cursor-pointer items-center text-sm focus:ring-offset-2">
              <div className="rounded-xl border border-gray-100" style={{ minWidth: '36px', minHeight: '36px' }}>
                <Avvvatars radius={5} value={email || ''} size={38} />
              </div>
              <div className="flex w-full items-center justify-between px-2">
                <div className="flex w-full flex-col items-start justify-start pl-1.5">
                  <span className="flex w-full text-sm font-semibold text-gray-800">My account</span>
                  <span
                    style={{
                      maxWidth: '18ch',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    className="-mt-0.5 w-full text-xs font-medium"
                  >
                    {email}
                  </span>
                </div>
                <PiDotsThreeOutlineFill size="18" />
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default TopNav;
