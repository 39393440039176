import { useEffect, useState } from 'react';

import { Button, message } from 'antd';
import { capitalize } from 'lodash';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useApp } from './AppContext';
import SyncConfirmationModal from './ConfirmationModal';
import { PipedriveService } from './services/PipedriveService';

type IntegrationItem = {
  name: string;
  description: string;
  logoUrl: string;
  isConnected: boolean;
  /** Connect/Disconnect button */
  onPress: () => void;
  confirmTitle: string;
  confirmMessage: string;
  confirmText: string;
  disableMessage?: string;
};

const Powerups = () => {
  // Check if we are on the /powerups/hubspot?success=false route
  // If so, show the error message
  const params = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const integration = params['*'];

  console.log({
    location,
    params,
    searchParams,
    success: searchParams.get('success'),
    integration,
  });

  useEffect(() => {
    if (integration === 'hubspot' || integration === 'pipedrive') {
      if (searchParams.get('success') == 'false') {
        message.error(`There was an error connecting to ${capitalize(integration)}`);
      } else if (searchParams.get('success') == 'true') {
        message.success(`Successfully connected to ${capitalize(integration)}`);
      }
      // Remove the query params from the url so we don't show the message again
      setSearchParams({});
    }
  }, [searchParams, integration, setSearchParams]);
  const { assistant, account, refresh } = useApp();
  const [selectedIntegration, setSelectedIntegration] = useState<IntegrationItem | null>(null);

  if (!assistant) return null;

  const isPipedriveConnected = assistant?.powerups.pipedrive ? true : false;
  const isHubspotConnected = assistant?.powerups.hubspot ? true : false;
  const actualIntegrations: IntegrationItem[] = [
    {
      name: 'Pipedrive',
      description: 'Pipedrive is a sales customer relationship management (CRM) tool.',
      isConnected: isPipedriveConnected,
      logoUrl: 'https://s3.amazonaws.com/neverbounce-hosted/images/integrations/square/pipedrive-square.png',
      onPress: async () => {
        setSelectedIntegration(null);

        if (account?.id && assistant?.id) {
          // If we are connected
          if (isPipedriveConnected) {
            try {
              const response = await PipedriveService.uninstall({
                assistantId: assistant.id,
                accountId: account.id,
              });
              if (response.status === 200) {
                message.success('Successfully uninstalled Pipedrive');
                refresh();
              } else {
                console.error(response);
                message.error('Could not uninstall Pipedrive');
              }
            } catch (error) {
              message.error('Could not uninstall Pipedrive');
              console.error(error);
            }
            return;
          }
          // If we are not connected we start the oauth flow
          window.location.href = `${process.env.REACT_APP_API_URL}/pipedriveApp/auth/pipedrive?accountId=${account.id}&assistantId=${assistant.id}`;
        } else {
          console.error('Account or Assistant is null');
        }
      },
      confirmTitle: isPipedriveConnected ? 'Confirm Pipedrive uninstallation' : 'Important Pipedrive information',
      confirmMessage: isPipedriveConnected
        ? 'Uninstalling pipedrive will make you unable to push leads to pipedrive. Your existing pipedrive data will not be removed.'
        : 'To integrate the Sellto AI-Score functionality, we need to create a new data field in Pipedrive. If you are not an admin, the integration will work anyway but without the Sellto AI-Score information.',
      confirmText: isPipedriveConnected ? 'Uninstall pipedrive' : 'Integrate with Pipedrive',
    },
    {
      name: 'Hubspot',
      description: 'HubSpot is a customer relationship management (CRM) tool.',
      isConnected: isHubspotConnected,
      logoUrl: 'https://www.pngrepo.com/png/331433/180/hubspot.png',
      onPress: () => {
        setSelectedIntegration(null);
        if (account?.id && assistant?.id) {
          window.location.href = `${process.env.REACT_APP_API_URL}/hubSpotApp/start?accountId=${account.id}&assistantId=${assistant.id}`;
        } else {
          console.error('Account or Assistant is null');
        }
      },
      confirmTitle: 'Important Hubspot information',
      confirmMessage:
        'To integrate the Sellto AI-Score functionality, we need to create a new data field in Hubspot. If you are not an admin, the integration will work anyway but without the Sellto AI-Score information.',
      confirmText: 'Integrate with Hubspot',
      disableMessage: isHubspotConnected ? 'Already connected' : undefined,
    },
    {
      name: 'Zapier',
      description: 'Zapier is a web-based service that allows end users to integrate the web applications they use.',
      isConnected: false,
      logoUrl: 'https://cdn.icon-icons.com/icons2/2407/PNG/512/zapier_icon_146029.png',
      confirmMessage: '',
      confirmText: '',
      confirmTitle: '',
      onPress: () => {},
      disableMessage: 'Coming soon',
    },
  ];

  return (
    <>
      <SyncConfirmationModal
        isOpen={selectedIntegration !== null}
        title={selectedIntegration?.confirmTitle || ''}
        message={selectedIntegration?.confirmMessage || ''}
        confirmText={selectedIntegration?.confirmText || ''}
        cancelText="Cancel"
        onClose={() => setSelectedIntegration(null)}
        onConfirm={selectedIntegration?.onPress}
      />
      <div className="mx-auto max-w-6xl">
        <div className="mx-auto min-h-full">
          <div id="CustomizeHeader" className="mb-6 flex items-center justify-between">
            <div className="text-2xl font-bold">Connections</div>
          </div>
        </div>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {actualIntegrations.map((integrationData) => (
            <li
              key={integrationData.name}
              className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
              // TW opacity class didnt work for some reason
              style={{ opacity: integrationData.disableMessage ? 0.5 : 1 }}
            >
              <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1">
                  <img
                    className="mb-6 h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                    src={integrationData.logoUrl}
                    alt=""
                  />
                  <div className="flex items-center space-x-3">
                    <h3 className="text-sm font-medium text-gray-900">{integrationData.name}</h3>
                    {integrationData.isConnected && (
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Connected
                      </span>
                    )}
                  </div>
                  <p className="mt-1 text-sm text-gray-500">{integrationData.description}</p>
                </div>
              </div>
              <div className="px-6 pb-2 pt-4">
                <Button
                  disabled={!!integrationData.disableMessage}
                  className="w-full text-gray-500"
                  onClick={() => {
                    setSelectedIntegration(integrationData);
                  }}
                >
                  {integrationData.disableMessage
                    ? integrationData.disableMessage
                    : integrationData.isConnected
                      ? 'Disconnect'
                      : 'Connect'}
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Powerups;
