import React, { ReactNode } from 'react';

import { Oval } from 'react-loader-spinner';

type SelltoButtonProps = {
  children: ReactNode;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  onClick?: () => void;
  type?: 'standard' | 'black' | 'loading';
  loading?: boolean;
  disabled?: boolean;
  size?: 'default' | 'large'; // Ny prop för storlek
};

const SelltoButton: React.FC<SelltoButtonProps> = ({
  children,
  icon = null,
  iconPosition = 'right',
  onClick,
  type = 'standard',
  loading = false,
  disabled = false,
  size = 'default', // Standardvärdet är 'default'
}) => {
  const renderContent = () => {
    return (
      <>
        {loading && (
          <Oval
            color={type === 'black' ? 'white' : 'black'}
            secondaryColor={type === 'black' ? 'lightgray' : 'gray'}
            height={13}
            width={13}
            visible={true}
            strokeWidth={8}
          />
        )}
        {iconPosition === 'left' && icon}
        {children}
        {iconPosition === 'right' && icon}
      </>
    );
  };

  const buttonClass = () => {
    if (disabled) {
      return 'bg-gray-100 text-gray-400 cursor-not-allowed';
    }
    switch (type) {
      case 'black':
        return 'text-white bg-black hover:bg-gray-800';
      default:
        return 'text-black bg-neutral-200 hover:bg-neutral-300';
    }
  };

  const sizeClass = size === 'large' ? 'text-sm py-2 px-4 ' : 'text-xs py-1.5 px-3 ';

  return (
    <button
      type="button"
      className={`flex items-center justify-start gap-1 rounded-lg border-none ${sizeClass} font-medium shadow-none transition-all ${buttonClass()}`}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {renderContent()}
    </button>
  );
};

export default SelltoButton;
