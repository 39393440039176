import React, { useContext, useEffect, useState } from 'react';

import { DialogueData, Themes } from 'constants/src';

export type ThemeContextType = {
  name: 'dark' | 'light';
  colors: {
    primary: string;
    secondary: string;
    background: string;
    surface: string;
    error: string;
    onPrimary: string;
    onSecondary: string;
    onBackground: string;
    onSurface: string;
    onError: string;
  };
};

const initialValue: ThemeContextType = {
  name: 'dark',
  colors: {
    primary: '#000000',
    secondary: '#000000',
    background: '#000000',
    surface: '#000000',
    error: '#000000',
    onPrimary: '#000000',
    onSecondary: '#000000',
    onBackground: '#000000',
    onSurface: '#000000',
    onError: '#000000',
  },
};

const ThemeContext = React.createContext<ThemeContextType>(initialValue);

type Props = {
  children: React.ReactNode;
  data?: DialogueData;
};

const extractThemeName = (theme: Themes) => {
  if (theme === 'auto') {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    } else {
      return 'light';
    }
  }
  return theme;
};

export const ThemeProvider = ({ children, data }: Props) => {
  const [theme, setTheme] = useState<ThemeContextType>(initialValue);

  // Set the theme on our container element and then retrieve the theme colors
  useEffect(() => {
    if (!data) return;
    const themeRoot = document.getElementById('sellto-ai-flow') || document.getElementById('sellto-ai-widget');

    if (!themeRoot) {
      console.error('Could not find Sellto-ai theme');
    }
    const themeName = extractThemeName(data?.designSettings?.theme || 'dark');

    themeRoot?.setAttribute('data-theme', themeName);
    const style = getComputedStyle(themeRoot || document.body);
    // loop over all theme.colors and get the values from their corresponding css variable,
    // where the name of the css variable is always--color - <color>
    // and a capital letter is replaced with a dash instead
    const colors = Object.fromEntries(
      Object.entries(initialValue.colors).map(([key, value]) => {
        const cssVar = `--color-${key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)}`;
        return [key, style.getPropertyValue(cssVar) || value];
      })
    );
    setTheme({
      name: themeName,
      colors: colors as ThemeContextType['colors'],
    });
  }, [data]);

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider Context');
  }
  return context;
};
