import { useCallback, useState } from 'react';

import { Form, Input, message } from 'antd';
import { doc, updateDoc } from 'firebase/firestore';

import { useApp } from './AppContext';
import { firestore } from './firebase';
import SelltoButton from './subcomponents/SelltoButton';

const CompanySettings = () => {
  const { assistant, account } = useApp();

  const [companyName, setCompanyName] = useState<string>(account?.name || '');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveSetup = useCallback(async () => {
    if (!account || !assistant) {
      console.error('No account/assistant');
      return;
    }

    setSaving(true);

    setTimeout(async () => {
      try {
        const accountDocRef = doc(firestore, 'accounts', account.id);
        await updateDoc(accountDocRef, { name: companyName });

        setUnsavedChanges(false);
        message.success('Company settings updated sucessfully');
      } catch (error) {
        message.error('Could not update company settings');
      } finally {
        setSaving(false);
      }
    }, 1500);
  }, [account, assistant, companyName]);

  const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
    setUnsavedChanges(true);
  };

  return (
    <>
      <div className="mx-auto max-w-4xl">
        <div id="CustomizeHeader" className="mb-6 flex max-w-4xl  items-center justify-between">
          <div className="text-2xl font-bold">Settings</div>
        </div>
        <div className=" mb-8 overflow-hidden rounded-2xl border border-gray-200 bg-white">
          <div className="p-4">
            <div className="mb-4">
              <span className="text-md text-md mb-1 mt-0 font-bold ">General information</span>
            </div>
            <Form>
              <div className="mb-4">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Company name
                  <p className="mb-2 text-xs font-normal text-gray-600">
                    The name you set here is what the AI for all your flows will communicate as your company name. Using
                    things like AB/Inc/Ltd or similar, theese endings will also reflect the AIs response.
                  </p>
                </label>
                <Input
                  showCount
                  maxLength={30}
                  value={companyName}
                  id="company-name"
                  size="middle"
                  className="rounded-md border-gray-300"
                  placeholder="eg. E-commerce clients"
                  onChange={handleCompanyNameChange}
                />
              </div>
            </Form>
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Domain
                <p className="mb-2 text-xs font-normal text-gray-600">
                  This is your company domain. This section is locked. If you want to change domain, please contact us.
                </p>
                <Input
                  value={account?.domain}
                  id="company-domain"
                  size="middle"
                  className="rounded-md border-gray-300"
                  disabled={true}
                  placeholder="Your company domain"
                />
              </label>
            </div>
          </div>
          <div className="flex w-full items-center justify-end gap-4 border-t bg-white px-4 py-3">
            {unsavedChanges && <span className="text-xs text-gray-500">Remember to save your changes</span>}
            <SelltoButton onClick={saveSetup} type="black" disabled={!unsavedChanges} loading={saving}>
              Save
            </SelltoButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySettings;
