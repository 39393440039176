export const SelltoLogotype = (_props: { className?: string }) => {
  return (
    <svg width="45.9" height="11.9" viewBox="0 0 54 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_30_437)">
        <path d="M0.194967 9.8094V9.74362H3.46299V9.94094C3.46299 10.9369 4.07574 11.2 5.92328 11.2C7.77082 11.2 8.07719 10.9087 8.07719 10.251C8.07719 9.59329 7.75226 9.46174 6.46176 9.28322L3.38871 8.90738C1.21622 8.6631 0 7.66712 0 5.81612C0 3.96511 1.63401 2.3302 5.3848 2.3302C9.13558 2.3302 10.9832 3.70201 10.9832 6.38926V6.45503H7.71512V6.3235C7.71512 5.42149 7.27876 4.95169 5.27339 4.95169C3.26801 4.95169 3.22159 5.24295 3.22159 5.94765C3.22159 6.65235 3.52797 6.7651 4.96702 6.94363L7.29733 7.2349C10.2219 7.56376 11.3081 8.55974 11.3081 10.3919C11.3081 12.2242 9.2841 13.8309 5.90471 13.8309C2.52529 13.8309 0.194967 12.3651 0.194967 9.8094Z" />
        <path d="M48.0983 14C47.1603 14 46.3265 13.8542 45.597 13.5626C44.8674 13.2577 44.2486 12.8468 43.7406 12.3299C43.2455 11.813 42.8676 11.2231 42.6071 10.5603C42.3466 9.88438 42.2163 9.17524 42.2163 8.43298V7.97568C42.2163 7.22015 42.3466 6.5044 42.6071 5.82839C42.8807 5.13914 43.2715 4.53605 43.7796 4.01911C44.3007 3.50217 44.9261 3.0979 45.6556 2.80629C46.3852 2.50143 47.1993 2.349 48.0983 2.349C49.0233 2.349 49.844 2.50143 50.5605 2.80629C51.2901 3.0979 51.9088 3.50217 52.4169 4.01911C52.925 4.53605 53.3159 5.13914 53.5894 5.82839C53.8631 6.5044 53.9998 7.22015 53.9998 7.97568V8.43298C53.9998 9.17524 53.8695 9.88438 53.609 10.5603C53.3485 11.2231 52.9641 11.813 52.4561 12.3299C51.961 12.8468 51.3487 13.2577 50.6192 13.5626C49.8896 13.8542 49.0494 14 48.0983 14ZM48.0983 11.3159C48.7106 11.3159 49.2187 11.1834 49.6226 10.9182C50.0264 10.6532 50.3325 10.282 50.5409 9.80489C50.7624 9.32767 50.8732 8.79748 50.8732 8.21427C50.8732 7.59129 50.7624 7.04784 50.5409 6.58392C50.3195 6.10675 50.0003 5.72898 49.5834 5.45063C49.1796 5.17228 48.6845 5.03311 48.0983 5.03311C47.5251 5.03311 47.03 5.17228 46.6131 5.45063C46.1963 5.72898 45.8771 6.10675 45.6556 6.58392C45.4471 7.04784 45.3429 7.59129 45.3429 8.21427C45.3429 8.79748 45.4471 9.32767 45.6556 9.80489C45.8641 10.282 46.1767 10.6532 46.5936 10.9182C47.0105 11.1834 47.512 11.3159 48.0983 11.3159Z" />
        <path d="M11.6143 8.08993C11.6143 4.55705 14.0745 2.3302 17.6768 2.3302C21.2791 2.3302 23.628 4.2282 23.628 7.68591C23.628 11.1436 23.5815 8.37182 23.5444 8.74766H14.6501C14.7337 10.4483 15.5229 11.1718 17.6396 11.1718C19.7565 11.1718 20.2578 10.6644 20.2578 9.71544V9.49933H23.5258V9.74362C23.5258 12.149 21.214 13.8215 17.7511 13.8215C14.2881 13.8215 11.6235 12.0174 11.6235 8.08993H11.6143ZM14.6873 7.00939H20.4807C20.3971 5.55302 19.5243 4.91409 17.6025 4.91409C15.6807 4.91409 14.8359 5.57181 14.6781 7.00939H14.6873Z" />
        <path d="M24.5474 13.6054V0H27.8154V13.6054H24.5474Z" />
        <path d="M29.106 13.6054V0H32.374V13.6054H29.106Z" />
        <path d="M39.0213 13.6054C36.4682 13.6054 34.9271 12.3933 34.9271 9.61211V5.28996H33.2095V2.55573H34.9271V0.413452H38.1951V2.55573H41.723V5.28996H38.1951V9.23627C38.1951 10.3356 38.6128 10.6269 39.7641 10.6269H41.3146L41.723 13.6054H39.0213Z" />
      </g>
      <defs>
        <clipPath id="clip0_30_437">
          <rect width="54" height="14" />
        </clipPath>
      </defs>
    </svg>
  );
};
