import React, { useContext, useEffect, useState } from 'react';

import { Locale, supportedLocales } from 'constants/src';
import { IntlProvider, MessageFormatElement } from 'react-intl';

function loadLocaleData(locale: string) {
  switch (locale) {
    case 'sv':
      return import('../../lang/sv.json');
    default:
      return import('../../lang/en.json');
  }
}

type MessagesType = Record<string, string> | Record<string, MessageFormatElement[]> | undefined;

function userLocale(): Locale {
  // The navigator.language includes country/dialect as well (e.g. en-US),
  // but we only want the language(en)
  const locale = navigator.language.slice(0, 2);
  // We need to do this because typescript is stupid and wont allow us to
  // check includes on the original array since the types differ
  const sl: readonly string[] = supportedLocales;
  if (!sl.includes(locale)) {
    return 'en';
  }
  return locale as Locale;
}

export type IntlProviderWrapperType = {
  locale: Locale;
  setLanguage: (language: Locale) => void;
};

const IntlProviderWrapperContext = React.createContext<IntlProviderWrapperType>({
  locale: 'en',
  setLanguage: () => {},
});

type Props = {
  children: React.ReactNode;
  defaultLocale?: Locale;
};

export const IntlProviderWrapper = ({ children, defaultLocale }: Props) => {
  const [locale, setLocale] = useState(defaultLocale || userLocale());
  const [messages, setMessages] = useState<MessagesType>(undefined);

  useEffect(() => {
    loadLocaleData(locale)
      // @ts-expect-error - not sure why this is complaining
      .then((data: MessagesType) => {
        setMessages(data);
      });
  }, [locale]);

  return (
    <IntlProviderWrapperContext.Provider value={{ locale, setLanguage: setLocale }}>
      <IntlProvider locale={locale} defaultLocale="en" messages={messages}>
        {children}
      </IntlProvider>
    </IntlProviderWrapperContext.Provider>
  );
};

export const useLanguageChanger = () => {
  const context = useContext(IntlProviderWrapperContext);
  if (context === undefined) {
    throw new Error('useLanguageChanger must be used within a IntlProviderWrapper');
  }
  return context;
};
