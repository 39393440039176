import { useEffect, useState } from 'react';

import { Table } from 'antd';
import { User } from 'constants/src/types';
import { collection, documentId, getDocs, query, where } from 'firebase/firestore'; // Uppdaterad import
import { FaUserPlus } from 'react-icons/fa6';

import { useApp } from './AppContext';
import { firestore } from './firebase';
import SelltoButton from './subcomponents/SelltoButton';

type Member = User;

const CompanyMembers = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const [loading, setLoading] = useState(true);
  const { account } = useApp();

  useEffect(() => {
    async function fetchMembers() {
      if (!account || !account.members) {
        console.log('No account or members array');
        setLoading(false);
        return;
      }
      try {
        const usersRef = collection(firestore, 'users');

        // Skapa en fråga som matchar alla
        // dokument vars id finns i members arrayen
        const usersQuery = query(usersRef, where(documentId(), 'in', account.members));

        // Hämta alla dokument som matchar frågan
        const querySnapshot = await getDocs(usersQuery);

        if (querySnapshot.empty) {
          console.log('No matching documents.');
          return;
        }

        console.log('Query snapshot:', querySnapshot);

        const memberData = querySnapshot.docs.map((doc) => {
          const data = doc.data() as User;
          console.log('Member data:', data);
          return {
            ...data,
            id: doc.id,
          };
        });
        setMembers(memberData);
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchMembers();
  }, [account]);

  const columns = [
    {
      title: 'Förnamn',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Efternamn',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'E-post',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      render: (isAdmin: boolean) => (isAdmin ? 'Admin' : 'Member'),
    },
  ];

  return (
    <>
      <div className="mx-auto max-w-4xl">
        <div id="CustomizeHeader" className="mb-6 flex max-w-4xl  items-center justify-between">
          <div className="text-2xl font-bold">Company members</div>
          <SelltoButton iconPosition="left" icon={<FaUserPlus size={14} />} type="black">
            Add member
          </SelltoButton>
        </div>
        <Table dataSource={members} columns={columns} loading={loading} />
      </div>
    </>
  );
};

export default CompanyMembers;
