import { useCallback, useEffect, useMemo, useState } from 'react';

import { IconX } from '@tabler/icons-react';
import { Progress } from 'antd';
import type { AnswerResponse, ComplementResponse } from 'api/src/routes/dialogue';
import { DialogueData, Locale, localeTranslations } from 'constants/src';
import { AnimatePresence, motion } from 'framer-motion';
import { ErrorBoundary } from 'react-error-boundary';
import { FaCheck } from 'react-icons/fa';
import { PiArrowUpRightBold } from 'react-icons/pi';
import { useIntl } from 'react-intl';
import { z } from 'zod';

import { AnimatedBackground } from './components/AnimatedBackground';
import { ErrorScreen } from './components/ErrorScreen';
import { ExplanationScreen } from './components/ExplanationScreen';
import { LanguageToggle } from './components/LanguageToggle';
import { Loader } from './components/Loader';
import { MessageScreen } from './components/MessageScreen';
import { QuestionScreen } from './components/QuestionScreen';
import { IntlProviderWrapper, useLanguageChanger } from '../context/IntlProviderWrapper';
import { ThemeProvider } from '../context/ThemeContext';
import { SelltoLogotype } from '../Icons/SelltoLogotype';
import { getLocaleField } from '../utils/localeHelper';
import { minimumDelayedFunction } from '../utils/minimumDelayedFunction';
import { useVirtualKeyboard } from '../utils/useVirtualKeyboard';

import { messages } from './Dialogue.text';

export type ComplementaryInput = {
  customAnswers?: string[];
  language: Locale;
};

export type ResultInput = {
  leadId: string;
  answer: string;
};

export type UpdateContactInput = {
  leadId: string;
  email?: string;
  companyName?: string;
  name?: string;
  phoneNumber?: string;
};
type Props = {
  data?: DialogueData;
  loading?: boolean;
  error?: {
    title: string;
    onClick?: () => void;
    buttonText?: string;
    description?: string;
  };
  defaultLanguage?: Locale;
  getComplementary: (values: ComplementaryInput) => Promise<ComplementResponse>;
  getResults: (values: ResultInput) => Promise<AnswerResponse>;
  updateContact: (values: UpdateContactInput) => Promise<void>;
  /** If we are displaying the Dialogue in a widget
   * this function will close the widget.
   * If we are displaying the Dialogue on our own site
   * we dont provide the function */
  closeWidget?: () => void;
};

type ComplementaryData = {
  question: string;
  description: string;
  placeholder: string;
};

type ResultData = {
  score: number;
  description: string;
};

type ContactInfo = {
  email: string;
  companyName: string;
  name: string;
  phoneNumber: string;
};

const submitMinTime = 10000;
const getComplementaryMinTime = 3000;

const DialogueComponent = ({
  data,
  loading,
  error,
  getComplementary,
  getResults,
  updateContact,
  closeWidget,
}: Props) => {
  const { locale, setLanguage } = useLanguageChanger();
  const { formatMessage } = useIntl();
  const [step, setStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string>();

  const isWidget = useMemo(() => closeWidget !== undefined, [closeWidget]);

  const validatedEmail = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParams = params.get('email');
    const validEmailParams = z
      .string()
      .min(1, formatMessage(messages.validationAnswerRequired))
      .email(formatMessage(messages.validationInvalidEmail))
      .safeParse(emailParams).success;
    return validEmailParams ? emailParams : null;
  }, [formatMessage]);

  const defaultContactInfo: ContactInfo = useMemo(
    () => ({
      email: validatedEmail || '',
      companyName: '',
      name: '',
      phoneNumber: '',
    }),
    [validatedEmail]
  );

  const [complementaryData, setComplementaryData] = useState<ComplementaryData>();
  const [complementaryResponse, setComplementaryResponse] = useState<string>('');
  const [resultData, setResultData] = useState<ResultData>();
  const [contactInfo, setContactInfo] = useState<ContactInfo>(defaultContactInfo);
  const [leadId, setLeadId] = useState<string>('');
  // We start with an empty string for each question,
  // that way the input is always controlled
  const [customQuestionAnswers, setCustomQuestionAnswers] = useState<string[]>(data?.questions?.map(() => '') || []);

  /** The best result that is below the minimumScore,
   * or if no scoreThresholds matches it takes the defaultResult */
  const currentResult = useMemo(() => {
    const sortedThresholds = data?.scoreThresholds?.sort((a, b) => b.minScore - a.minScore);
    return sortedThresholds?.find((threshold) => threshold.minScore <= (resultData?.score || 0)) || data?.defaultResult;
  }, [data?.defaultResult, data?.scoreThresholds, resultData?.score]);

  useEffect(() => {
    document.addEventListener('touchstart', function (e) {
      e.preventDefault();
    });
    return () => {
      document.removeEventListener('touchstart', function (e) {
        e.preventDefault();
      });
    };
  }, []);

  const customQuestionsCount = useMemo(() => data?.questions?.length || 0, [data]);

  const stepForward = useCallback(() => {
    // We want to skip the email step
    setStep((v) => v + 1);
  }, []);

  const submitContact = useCallback(
    async (updateObject: { name?: string; email?: string; companyName?: string; phoneNumber?: string }) => {
      setSubmitting(true);
      try {
        await updateContact({
          leadId,
          ...updateObject,
        });
        stepForward();
      } catch (err) {
        // setError('Could not submit contact information');
        console.error({ err });
      } finally {
        setSubmitting(false);
      }
    },
    [leadId, stepForward, updateContact]
  );

  const requestComplementaryData = useCallback(async () => {
    stepForward();
    setSubmitting(true);
    setSubmitError(undefined);

    try {
      const response = await minimumDelayedFunction(
        () =>
          getComplementary({
            language: locale || 'en',
            customAnswers: customQuestionAnswers,
          }),
        data?.aiEnabled ? getComplementaryMinTime : 0,
        { minErrorDelay: data?.aiEnabled ? 1000 : 0 }
      );
      if ('error' in response) {
        throw new Error(response.error);
      }

      if (response.question && data?.aiEnabled) {
        setComplementaryData({
          question: response.question,
          description: response.description || '',
          placeholder: response.placeholder || '',
        });
      }
      setSubmitting(false);
      setLeadId(response.leadId);
      if (validatedEmail && response.leadId) {
        await updateContact({
          leadId: response.leadId,
          email: validatedEmail,
        });
      }
    } catch (err) {
      console.error({ err });
      setStep((v) => v - 1);

      setSubmitError(formatMessage(messages.followUpError));
      setSubmitting(false);
    }
  }, [
    stepForward,
    data?.aiEnabled,
    getComplementary,
    locale,
    customQuestionAnswers,
    validatedEmail,
    updateContact,
    formatMessage,
  ]);

  const submitResults = useCallback(async () => {
    stepForward();
    setSubmitting(true);
    setSubmitError(undefined);

    try {
      const response = await minimumDelayedFunction(
        () =>
          getResults({
            leadId,
            answer: complementaryResponse,
          }),
        submitMinTime,
        { minErrorDelay: 1000 }
      );

      if ('error' in response) {
        throw new Error(response.error);
      }
      setSubmitting(false);
      setResultData({
        score: response.score,
        description: response.description,
      });
    } catch (err) {
      setStep((v) => v - 1);
      setSubmitError(formatMessage(messages.resultError));
      setSubmitting(false);
      console.error({ err });
    }
  }, [complementaryResponse, formatMessage, getResults, leadId, stepForward]);

  const resetForm = useCallback(() => {
    setStep(1);
    setComplementaryData(undefined);
    setComplementaryResponse('');
    setResultData(undefined);
    setContactInfo(defaultContactInfo);
    setLeadId('');
    setCustomQuestionAnswers([]);
  }, [defaultContactInfo]);

  /* This is used to control progress/animations.
  It will wait until we have input to count the step */
  const animationStep = useMemo(() => {
    let newStep = step;
    if (step > 0 && step < 1 + customQuestionsCount) {
      if (!customQuestionAnswers[step - 1]?.length) {
        newStep -= 1;
      }
    }
    if (step === 1 + customQuestionsCount) {
      if (!complementaryResponse?.length) {
        newStep -= 1;
      }
    }
    return newStep;
  }, [complementaryResponse?.length, customQuestionAnswers, customQuestionsCount, step]);

  const renderStep = useCallback(() => {
    // Checks if there's no data preset in the question flow
    if (data && data.questions && data.questions.length === 0) {
      return (
        <ErrorScreen
          title={formatMessage(messages.disabledTitle)}
          description={formatMessage(messages.disabledDescription)}
          showIcon
        />
      );
    }
    if (!data) return null;
    // Welcome
    if (step === 0) {
      return (
        <MessageScreen
          text={getLocaleField(data.welcome.title, locale)}
          buttonText={getLocaleField(data.welcome.buttonText, locale)}
          onClick={stepForward}
        />
      );
    }
    // Custom Questions
    if (step > 0 && step < 1 + customQuestionsCount) {
      const question = data.questions?.[step - 1];
      return (
        <QuestionScreen
          key={step}
          validator={(text) => text.trim().length <= 0 && formatMessage(messages.validationAnswerRequired)}
          value={customQuestionAnswers[step - 1]}
          onChange={(e) => {
            const newAnswers = [...customQuestionAnswers];
            newAnswers[step - 1] = e;
            setCustomQuestionAnswers(newAnswers);
          }}
          onClick={() => {
            // If its the last custom question we call requestComplementaryData
            if (step === customQuestionsCount) {
              requestComplementaryData();
            } else {
              stepForward();
            }
          }}
          title={getLocaleField(question?.title, locale)}
          description={getLocaleField(question?.description, locale)}
        />
      );
    }

    // Complementary question
    if (step === 1 + customQuestionsCount && data.aiEnabled) {
      if (submitting) {
        const submittingMessages = [
          formatMessage(messages.complementaryLoading1),
          formatMessage(messages.complementaryLoading2),
          formatMessage(messages.complementaryLoading3),
        ];
        return (
          <Loader messages={submittingMessages} changeTime={getComplementaryMinTime / submittingMessages.length} />
        );
      }
      if (submitError) {
        return (
          <ErrorScreen
            title={formatMessage(messages.errorTitle)}
            onClick={requestComplementaryData}
            buttonText={formatMessage(messages.tryAgainButtonText)}
            showIcon
          />
        );
      }
      return (
        <QuestionScreen
          key={step}
          validator={(text) => text.trim().length <= 0 && formatMessage(messages.validationAnswerRequired)}
          value={complementaryResponse}
          onChange={(e) => setComplementaryResponse(e)}
          onClick={submitResults}
          title={complementaryData?.question || ''}
          description={complementaryData?.description || ''}
        />
      );
    }

    // Results
    if (step === 2 + customQuestionsCount && data.aiEnabled) {
      if (submitting) {
        const submittingMessages = [
          formatMessage(messages.resultLoading1),
          formatMessage(messages.resultLoading2),
          formatMessage(messages.resultLoading3),
          formatMessage(messages.resultLoading4),
        ];
        return (
          <div className="flex h-full flex-col items-center justify-center">
            <Loader
              messages={submittingMessages}
              changeTime={submitMinTime / submittingMessages.length}
              loaderHelpText={formatMessage(messages.loaderHelpText)}
            />
          </div>
        );
      }
      if (submitError || !currentResult) {
        return (
          <ErrorScreen
            title={formatMessage(messages.errorTitle)}
            onClick={submitResults}
            buttonText={formatMessage(messages.tryAgainButtonText)}
            showIcon
          />
        );
      }
      return (
        <MessageScreen
          text={getLocaleField(currentResult.title, locale)}
          buttonText={formatMessage(messages.explainWhyButtonText)}
          onClick={stepForward}
        />
      );
    }

    // Explanation
    if (step === 3 + customQuestionsCount && data.aiEnabled) {
      if (!currentResult) {
        return (
          <ErrorScreen
            title={formatMessage(messages.errorTitle)}
            onClick={submitResults}
            buttonText={formatMessage(messages.tryAgainButtonText)}
            showIcon
          />
        );
      }
      return (
        <ExplanationScreen
          text={resultData?.description || ''}
          buttonText={getLocaleField(currentResult.buttonText, locale)}
          onClick={() => {
            switch (currentResult.action) {
              case 'restart':
                resetForm();
                break;
              case 'close': {
                if (closeWidget) {
                  closeWidget();
                } else {
                  const url = currentResult.url.startsWith('http') ? currentResult.url : `https://${currentResult.url}`;
                  window.open(url, '_self');
                }
                break;
              }
              case 'url': {
                const url = currentResult.url.startsWith('http') ? currentResult.url : `https://${currentResult.url}`;
                window.open(url, '_self');
                break;
              }
              case 'proceed':
                stepForward();
                break;
              // This default case is just as an echaustive switch check.
              // We should never be able to enter default case
              default: {
                const exhaustiveCheck: never = currentResult.action;
                throw new Error(`Unhandled case: ${exhaustiveCheck}`);
              }
            }
          }}
        />
      );
    }

    // Contact info email

    if (step === (data.aiEnabled ? 4 : 1) + customQuestionsCount && data.collectContact) {
      return (
        <QuestionScreen
          key={step}
          validator={(text) => {
            const result = z
              .string()
              .min(1, formatMessage(messages.validationAnswerRequired))
              .email(formatMessage(messages.validationInvalidEmail))
              .safeParse(text);
            if (!result.success) {
              return result.error.issues[0].message;
            }
            return false;
          }}
          value={contactInfo.email}
          onChange={(e) => setContactInfo((v) => ({ ...v, email: e }))}
          onClick={() => {
            submitContact({ email: contactInfo.email });
          }}
          title={formatMessage(messages.contactEmailTitle)}
          description={formatMessage(messages.contactEmailDescription)}
        />
      );
    }

    // Contact info name
    if (step === (data.aiEnabled ? 5 : 2) + customQuestionsCount && data.collectContact) {
      return (
        <QuestionScreen
          key={step}
          validator={(text) => text.trim().length <= 0 && formatMessage(messages.validationAnswerRequired)}
          value={contactInfo.name}
          onChange={(e) => setContactInfo((v) => ({ ...v, name: e }))}
          onClick={() => {
            submitContact({ name: contactInfo.name });
          }}
          title={formatMessage(messages.contactNameTitle)}
          description={formatMessage(messages.contactNameDescription)}
        />
      );
    }

    // Contact info company name
    if (step === (data.aiEnabled ? 6 : 3) + customQuestionsCount && data.collectContact) {
      return (
        <QuestionScreen
          key={step}
          validator={(text) => text.trim().length <= 0 && formatMessage(messages.validationAnswerRequired)}
          value={contactInfo.companyName}
          onChange={(e) => setContactInfo((v) => ({ ...v, companyName: e }))}
          onClick={() => {
            submitContact({ companyName: contactInfo.companyName });
          }}
          title={formatMessage(messages.contactCompanyTitle)}
          description={formatMessage(messages.contactCompanyDescription)}
        />
      );
    }

    // Contact info phone number
    if (step === (data.aiEnabled ? 7 : 4) + customQuestionsCount && data.collectContact) {
      return (
        <QuestionScreen
          key={step}
          validator={(text) => {
            // This field is optional, so if its empty we just skip validation
            if (!text.trim().length) return false;

            const result = z
              .string()
              .min(10, formatMessage(messages.validationInvalidPhone))
              .max(17, formatMessage(messages.validationInvalidPhone))
              .safeParse(text);
            if (!result.success) {
              return result.error.issues[0].message;
            }
            return false;
          }}
          value={contactInfo.phoneNumber}
          onChange={(e) => setContactInfo((v) => ({ ...v, phoneNumber: e }))}
          onClick={() => {
            submitContact({ phoneNumber: contactInfo.phoneNumber });
          }}
          title={formatMessage(messages.contactPhoneTitle)}
          description={formatMessage(messages.contactPhoneDescription)}
        />
      );
    }

    // Final thank you screen
    if (step === customQuestionsCount + 8 - (data.collectContact ? 0 : 4) - (data.aiEnabled ? 0 : 3)) {
      return (
        <MessageScreen
          text={getLocaleField(data.afterSubmit.title, locale)}
          buttonText={getLocaleField(data.afterSubmit.buttonText, locale)}
          onClick={() => {
            switch (data.afterSubmit.action) {
              case 'restart':
                resetForm();
                break;
              case 'proceed':
              case 'close': {
                if (closeWidget) {
                  closeWidget();
                } else {
                  const url = data.afterSubmit.url.startsWith('http')
                    ? data.afterSubmit.url
                    : `https://${data.afterSubmit.url}`;
                  window.open(url, '_self');
                }
                break;
              }
              case 'url': {
                const url = data.afterSubmit.url.startsWith('http')
                  ? data.afterSubmit.url
                  : `https://${data.afterSubmit.url}`;
                window.open(url, '_self');
                break;
              }
              // This default case is just as an echaustive switch check.
              // We should never be able to enter default case
              default: {
                const exhaustiveCheck: never = data.afterSubmit.action;
                throw new Error(`Unhandled case: ${exhaustiveCheck}`);
              }
            }
          }}
        />
      );
    }

    // We should not be able to get this far
    return (
      <ErrorScreen
        title={formatMessage(messages.errorTitle)}
        onClick={window.location.reload}
        buttonText={formatMessage(messages.reloadButtonText)}
        showIcon
      />
    );
  }, [
    data,
    step,
    customQuestionsCount,
    formatMessage,
    locale,
    stepForward,
    customQuestionAnswers,
    requestComplementaryData,
    submitting,
    submitError,
    complementaryResponse,
    submitResults,
    complementaryData?.question,
    complementaryData?.description,
    currentResult,
    resultData?.description,
    resetForm,
    closeWidget,
    contactInfo,
    submitContact,
  ]);

  const showingVirtualKeyboard = useVirtualKeyboard();
  // Changed so that the footer renders the
  // "powered by sellto" in the bottomrender non devicedependent.
  const renderFooter = useCallback(() => {
    return (
      <motion.div
        key="footer"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3 }}
        className="absolute bottom-0 z-50 flex w-full justify-center px-10 pb-2 md:pb-6 lg:pb-12"
      >
        <div>
          <a
            href={`https://www.sellto.ai?utm_source=fromFlow`}
            target="_blank"
            className="text-on-background hover:text-on-surface flex cursor-pointer items-center justify-center gap-1 text-sm font-normal transition duration-300 ease-in-out"
            rel="noreferrer"
          >
            {formatMessage(messages.poweredBy)}{' '}
            <div className="flex items-center justify-center">
              <SelltoLogotype />
              <PiArrowUpRightBold />
            </div>
          </a>
        </div>
      </motion.div>
    );
  }, [formatMessage]);

  const renderTopRow = useCallback(() => {
    // We add +2 because the first step is welcome
    // screen and there is always a complementary question.
    const percent =
      (Math.min(step, customQuestionsCount + (data?.aiEnabled ? 2 : 1)) /
        (customQuestionsCount + (data?.aiEnabled ? 2 : 1))) *
      100;
    return (
      <motion.div
        key="topRow"
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -100 }}
        transition={{ duration: 0.3 }}
        layout
        className={`absolute top-4 z-50 flex w-full items-center  px-8 sm:top-10 ${
          isWidget ? 'justify-between' : 'justify-center sm:justify-between'
        }`}
      >
        <AnimatePresence mode="wait" initial={false}>
          {step === 0 ? (
            <motion.div
              key="languageToggle"
              initial={{ width: '36px', height: '36px' }}
              animate={{ width: 'auto', height: '36px' }}
              exit={{ width: '36px', height: '36px' }}
              transition={{ duration: 0.3 }}
            >
              <LanguageToggle
                locale={locale}
                setLocale={setLanguage}
                supportedLocales={localeTranslations.filter((l) => data?.languages.includes(l.value))}
              />
            </motion.div>
          ) : (
            <motion.div
              key="progress"
              initial={{ scale: 0.7 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              transition={{
                type: 'spring',
                damping: 8,
                mass: 1,
                stiffness: 80,
              }}
            >
              <Progress
                key="progressCircle"
                type="circle"
                size={36}
                strokeWidth={10}
                strokeColor={'var(--color-primary)'}
                percent={percent}
                // We set this to prevent the text from
                // becoming green on completion
                status="normal"
                format={
                  () => (
                    <div className="text-on-background flex items-center justify-center">
                      {step < (data?.aiEnabled ? 2 : 1) + customQuestionsCount ? (
                        `${Math.min(step, customQuestionsCount + (data?.aiEnabled ? 2 : 1))}/${customQuestionsCount + (data?.aiEnabled ? 2 : 1)}`
                      ) : (
                        <FaCheck size={20} />
                      )}
                    </div>
                  )
                  // Visa checkmark när alla steg är färdiga
                }
                // TODO: Change this font to sora.
                // We dont support it from tailwind font changes yet
                className="text-on-background text-base font-extrabold"
              />
            </motion.div>
          )}
        </AnimatePresence>
        <div className={`sm:flex ${!isWidget ? 'hidden' : 'flex'}`}>
          {isWidget ? (
            <button
              type="button"
              onClick={closeWidget}
              className="text-primary hover:text-primary-variant focus:text-primary-variant transition-all duration-300"
              name="Close widget"
            >
              <IconX size={36} />
            </button>
          ) : null}
        </div>
      </motion.div>
    );
  }, [closeWidget, customQuestionsCount, data?.languages, isWidget, locale, setLanguage, step]);

  if (loading) {
    return (
      <motion.div
        key="loading"
        className="text-on-background relative flex h-screen w-full items-center justify-center transition-all duration-300"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="inline-flex items-center justify-center gap-2">
          <div className="text-on-background text-sm font-normal">{formatMessage(messages.loadingFlow)}</div>
          <SelltoLogotype />
          <svg
            className="text-on-background h-5 w-5 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </motion.div>
    );
  }

  if (error) {
    <motion.div
      key="error"
      className="mx-6 mb-5 flex h-screen items-center justify-center transition-all duration-300 sm:mx-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ErrorScreen {...error} showIcon />
    </motion.div>;
  }

  // Not Found
  if (!data) {
    return (
      <div className="mx-10 h-full w-full max-w-lg pb-16 pt-24 sm:max-w-xl md:max-w-2xl lg:max-w-5xl">
        <ErrorScreen
          title={formatMessage(messages.notFoundTitle)}
          onClick={() => window.location.reload()}
          buttonText={formatMessage(messages.reloadButtonText)}
          showIcon
        />
      </div>
    );
  }

  // Assistant Disabled
  if (!data.enabled) {
    return (
      <div className="mx-10 h-full w-full max-w-lg pb-16 pt-24 sm:max-w-xl md:max-w-2xl lg:max-w-5xl">
        <ErrorScreen
          title={formatMessage(messages.disabledTitle)}
          description={
            <div className=" text-on-background flex gap-1 text-sm font-normal">
              {formatMessage(messages.disabledDescription)}
              <a
                href="https://sellto.ai"
                target="_blank"
                rel="noreferrer"
                className="text-on-background hover:text-on-surface flex cursor-pointer items-center justify-center gap-1 text-sm font-normal transition duration-300 ease-in-out"
              >
                <SelltoLogotype />
                <PiArrowUpRightBold />
              </a>
            </div>
          }
        />
      </div>
    );
  }

  const gradientPercentage =
    step >= (data?.aiEnabled ? 3 : 2) + customQuestionsCount
      ? 90 // Sätt en fast procent när ExplanationScreen visas och för alla efterföljande steg
      : (Math.min(animationStep, customQuestionsCount + (data?.aiEnabled ? 2 : 1)) /
          (customQuestionsCount + (data?.aiEnabled ? 2 : 1))) *
        90;

  return (
    <div
      // We need to set the position to fixed for
      // Safari on iOS to work correctly
      className={`bg-background text-on-background fixed flex h-full w-full items-center justify-center transition-all duration-300 sm:relative ${
        showingVirtualKeyboard && 'virtualKeyboardVisible'
      }`}
      onTouchMove={(e) => {
        e.preventDefault();
      }}
    >
      <AnimatePresence>
        {/* This is the background gradient */}
        {data.designSettings?.showGradient && <AnimatedBackground key="bg" percent={gradientPercentage} />}
        {!showingVirtualKeyboard && renderTopRow()}
      </AnimatePresence>
      <div
        className={`z-20 h-full w-full px-10 transition-all duration-300 sm:mx-4 md:mx-10 lg:mx-20 ${
          showingVirtualKeyboard ? '' : 'pb-16 pt-24'
        }`}
        onTouchMove={(e) => {
          e.preventDefault();
        }}
      >
        <AnimatePresence mode="wait">{renderStep()}</AnimatePresence>
      </div>
      <AnimatePresence>{!showingVirtualKeyboard && renderFooter()}</AnimatePresence>
    </div>
  );
};

// We wrap the whole Dialogue with an ErrorBoundary.
// This needs to be inside the IntlWrapper so we can
// get the language and all translations
const ErrBoundaryComponent = (props: Props) => {
  const { formatMessage } = useIntl();

  return (
    <ErrorBoundary
      onError={(error, info) => {
        console.error({ error, info });
      }}
      fallbackRender={({ error, resetErrorBoundary }) => (
        <motion.div
          key="error"
          className="mx-6 mb-5 flex h-screen items-center justify-center transition-all duration-300 sm:mx-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ErrorScreen
            title={formatMessage(messages.errorTitle)}
            showIcon
            onClick={resetErrorBoundary}
            buttonText={formatMessage(messages.reloadButtonText)}
            description={error.message}
          />
        </motion.div>
      )}
    >
      <DialogueComponent {...props} />
    </ErrorBoundary>
  );
};

// We need to use this wrapper to add the Intl Context
export const Dialogue = ({ defaultLanguage, ...props }: Props) => (
  <IntlProviderWrapper defaultLocale={defaultLanguage}>
    <ThemeProvider data={props.data}>
      <ErrBoundaryComponent {...props} />
    </ThemeProvider>
  </IntlProviderWrapper>
);
