import { useCallback, useMemo, useState } from 'react';

import { ArrowLeftIcon, ArrowRightIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { Button, Slider, Tooltip, message } from 'antd';
import { Assistant, ScoreThreshold } from 'constants/src';
import { collection, doc, updateDoc } from 'firebase/firestore';
import 'flag-icons/css/flag-icons.min.css';
import { clamp, set } from 'lodash';

import { ResultInputs } from './ResultInputs';
import { useApp } from '../../../AppContext';
import { firestore } from '../../../firebase';
import SelltoButton from '../../../subcomponents/SelltoButton';

function getGradientColor(percentage: number, transparent: boolean) {
  const startColor = [173, 216, 230]; // Light Blue
  const endColor = [0, 0, 25]; // Almost Black

  const midColor = startColor.map((start, i) => {
    const end = endColor[i];
    const delta = end - start;
    return (start + (delta * percentage) / 100).toFixed(0);
  });

  return `rgba(${midColor.join(',')},${transparent ? '0.2' : '1'})`;
}

export const ScoreResultSection = ({ selectedLanguages }: { selectedLanguages: string[] }) => {
  const { assistant, account } = useApp();
  const [sliderIndex, setSliderIndex] = useState(0);
  // Note that the first section is always the defaultSection,
  // and that should always have minScore set to 0
  const [sectionsNew, setSectionsNew] = useState<ScoreThreshold[]>([
    ...(assistant ? [{ ...assistant.defaultResult, minScore: 0 }] : []),
    ...(assistant?.scoreThresholds || []),
  ]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saving, setSaving] = useState(false);
  const value = [...((sectionsNew && sectionsNew.map((v) => v.minScore)) || []), 100];

  const saveResults = useCallback(async () => {
    if (!account || !assistant) {
      console.error('No account/assistant');
      return;
    }

    setSaving(true); // Starta sparandets laddningsindikator

    // Använd setTimeout för att skapa en 2 sekunders fördröjning
    setTimeout(async () => {
      try {
        const assistantsCollection = collection(firestore, 'accounts', account.id, 'assistants');

        const assistantDocRef = doc(assistantsCollection, assistant.id);

        const updatedAssistant: Pick<Assistant, 'defaultResult' | 'scoreThresholds'> = {
          defaultResult: sectionsNew[0],
          scoreThresholds: sectionsNew.slice(1),
        };

        await updateDoc(assistantDocRef, updatedAssistant);
        setUnsavedChanges(false); // Återställ tillståndet för osparade ändringar
        message.success('Result screens updated successfully');
      } catch (error) {
        message.error('Could not update result screens');
        console.error('Error saving result screens:', error);
      } finally {
        setSaving(false); // Stoppa sparandets laddningsindikator
      }
    }, 2000); // 2000 millisekunder = 2 sekunder
  }, [account, assistant, sectionsNew]);

  const start = 0;
  const end = 100;

  const selectedSection = useMemo(() => {
    return sectionsNew[sliderIndex];
  }, [sectionsNew, sliderIndex]);

  const startSection = value[sliderIndex];
  const endSection = value[sliderIndex + 1];

  const gradient = `${
    (startSection !== 0 && `${getGradientColor(start, true)} 0%, `) || ''
  }${getGradientColor(startSection, true)} ${startSection}%, ${getGradientColor(
    startSection + 1,
    false
  )} ${startSection + 1}%, ${getGradientColor(endSection - 1, false)} ${
    endSection - 1
  }%, ${getGradientColor(endSection, true)} ${endSection}%${
    (endSection !== 100 && `, ${getGradientColor(end, true)} 100%`) || ''
  }`;

  const changeSection = (index: number) => {
    setSliderIndex(index);
  };

  const addSection = () => {
    const middleScore = (startSection + endSection) / 2;
    const newSection: ScoreThreshold = {
      title: {
        en: '',
        sv: '',
      },
      description: {
        en: '',
        sv: '',
      },
      buttonText: {
        en: '',
        sv: '',
      },
      action: 'proceed',
      url: account?.domain || '',
      minScore: Math.round(middleScore),
    };
    const newSections = [...sectionsNew];
    newSections.splice(sliderIndex + 1, 0, newSection);
    setSectionsNew(newSections);
    setUnsavedChanges(true);
  };

  const deleteSection = () => {
    const newSections = [...sectionsNew];
    const isLastSection = sliderIndex >= newSections.length - 1;
    if (!isLastSection) {
      newSections[sliderIndex + 1].minScore = selectedSection.minScore;
    }
    newSections.splice(sliderIndex, 1);
    if (isLastSection) {
      setSliderIndex(sliderIndex - 1);
    }
    setSectionsNew(newSections);
  };

  const updateSection = (
    field:
      | 'title.en'
      | 'title.sv'
      | 'description.en'
      | 'description.sv'
      | 'buttonText.en'
      | 'buttonText.sv'
      | 'action'
      | 'url',
    fieldValue: string | boolean
  ) => {
    const newSection = { ...selectedSection };
    set(newSection, field, fieldValue);
    const newSections = [...sectionsNew];
    newSections[sliderIndex] = newSection;
    setSectionsNew(newSections);
    setUnsavedChanges(true);
  };

  const canAddSection = endSection - startSection > 10;

  return (
    <>
      <div className="mb-10 overflow-hidden rounded-lg border border-gray-200 bg-white">
        <div className="px-4 pt-4">
          <div>
            <div className="mb-6">
              <span className="text-md mb-0 mt-0 font-bold">Results page</span>
              <p className="text-xms text-gray-600">
                Depedning on the score your lead retrieves, you can customize what is it you want to tell the respondet
                about their calculated score. You can set up multiple ranges, specifying different messages depedning on
                the calculated score.
              </p>
            </div>
          </div>
          <div className="flex w-full items-center justify-center gap-6">
            <div className="flex justify-center gap-2">
              <Button
                onClick={() => changeSection(sliderIndex - 1)}
                disabled={sliderIndex === 0}
                icon={<ArrowLeftIcon className="h-5 w-5 text-gray-500" />}
              />
              <div>
                <div className="flex items-center justify-center gap-2">
                  <Tooltip title="Remove this score threshold">
                    <Button
                      disabled={sliderIndex === 0}
                      onClick={deleteSection}
                      icon={<MinusIcon className="h-5 w-5 text-gray-500" />}
                    />
                  </Tooltip>
                  <Tooltip title={canAddSection ? 'Add a new score threshold' : 'Too narrow score threshold'}>
                    <Button
                      disabled={!canAddSection}
                      onClick={addSection}
                      icon={<PlusIcon className="h-5 w-5 text-gray-500" />}
                    />
                  </Tooltip>
                </div>
              </div>

              <Button
                onClick={() => changeSection(sliderIndex + 1)}
                disabled={sliderIndex === sectionsNew.length - 1}
                icon={<ArrowRightIcon className="h-5 w-5 text-gray-500" />}
              />
            </div>
            <Slider
              className="w-full"
              value={value}
              range={{ draggableTrack: true }}
              onChange={(v: number[]) => {
                const minDistance = 10;
                const newScoreThresholds = [...sectionsNew].map((section, i) => {
                  if (i === 0) {
                    return {
                      ...section,
                      minScore: 0,
                    };
                  }
                  const minAllowedValue = v[i - 1] + minDistance;
                  const maxAllowedValue = v[i + 1] - minDistance;
                  return {
                    ...section,
                    // clamp it between minAllowedValue and maxAllowedValue
                    minScore: clamp(v[i], minAllowedValue, maxAllowedValue),
                  };
                });
                setSectionsNew(newScoreThresholds);
              }}
              styles={{
                track: {
                  background: 'transparent',
                },
                tracks: {
                  background: `linear-gradient(to right, ${gradient})`,
                },
              }}
            />
          </div>

          <div className="text-md mb-0 mt-5 font-semibold">
            Changing display for score range: {`${startSection}-${endSection}`}
          </div>

          <ResultInputs
            section={selectedSection}
            updateSection={updateSection}
            type="scoreThreshold"
            selectedLanguages={selectedLanguages}
          />
        </div>
        <div className="flex w-full items-center justify-end gap-4 border-t bg-white px-4 py-3">
          <div className="flex items-center justify-center gap-4">
            {unsavedChanges && <span className="text-xs text-gray-500">Remember to save your changes</span>}

            <SelltoButton onClick={saveResults} disabled={!unsavedChanges} loading={saving} type="black">
              Save
            </SelltoButton>
          </div>
        </div>
      </div>
    </>
  );
};
