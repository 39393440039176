import type { PipedriveUninstallRequest } from 'api/src/pipedriveAuth';
import { getAuth } from 'firebase/auth';

export class PipedriveService {
  static async uninstall({ accountId, assistantId }: PipedriveUninstallRequest) {
    const authToken = await getAuth().currentUser?.getIdToken();
    return await fetch(`${process.env.REACT_APP_API_URL}/pipedriveApp/auth/pipedrive/uninstall`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        accountId,
        assistantId,
      } as PipedriveUninstallRequest),
    });
  }
}
