import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  contactEmailTitle: {
    id: 'dialogue.contactEmailTitle',
    defaultMessage: 'Please enter your email',
  },
  contactEmailDescription: {
    id: 'dialogue.contactEmailDescription',
    defaultMessage: 'Use your work/company email',
  },
  contactCompanyTitle: {
    id: 'dialogue.contactCompanyTitle',
    defaultMessage: 'Please enter your company name',
  },
  contactCompanyDescription: {
    id: 'dialogue.contactCompanyDescription',
    defaultMessage: 'Enter your company name here',
  },
  contactNameTitle: {
    id: 'dialogue.contactNameTitle',
    defaultMessage: 'Please enter your name',
  },
  contactNameDescription: {
    id: 'dialogue.contactNameDescription',
    defaultMessage: 'Write your first and last name.',
  },
  contactPhoneTitle: {
    id: 'dialogue.contactPhoneTitle',
    defaultMessage: 'Please enter your phone number',
  },
  contactPhoneDescription: {
    id: 'dialogue.contactPhoneDescription',
    defaultMessage: 'Please provide your contact number.',
  },
  disabledTitle: {
    id: 'dialogue.disabledTitle',
    defaultMessage: 'This AI Flow is currently offline..',
  },
  disabledDescription: {
    id: 'dialogue.disabledDescription',
    defaultMessage: 'Explore new opportunities with',
  },
  notFoundTitle: {
    id: 'dialogue.notFoundTitle',
    defaultMessage: 'Flow not found',
  },
  notFoundDescription: {
    id: 'dialogue.notFoundDescription',
    defaultMessage: 'We could not find the page you were looking for.',
  },
  errorTitle: {
    id: 'dialogue.errorTitle',
    defaultMessage: 'Sorry, it seems we have encountered an error...',
  },
  reloadButtonText: {
    id: 'dialogue.reloadButtonText',
    defaultMessage: 'Reload',
  },
  tryAgainButtonText: {
    id: 'dialogue.tryAgainButtonText',
    defaultMessage: 'Try again',
  },
  explainWhyButtonText: {
    id: 'dialogue.explainWhyButtonText',
    defaultMessage: 'Read why',
  },
  poweredBy: {
    id: 'dialogue.poweredBy',
    defaultMessage: 'Powered by',
  },
  validationAnswerRequired: {
    id: 'dialogue.validationAnswerRequired',
    defaultMessage: 'Answer is required',
  },
  validationInvalidEmail: {
    id: 'dialogue.validationInvalidEmail',
    defaultMessage: 'Invalid email',
  },
  validationInvalidPhone: {
    id: 'dialogue.validationInvalidPhone',
    defaultMessage: 'Invalid phone number',
  },
  followUpError: {
    id: 'dialogue.followUpError',
    defaultMessage: 'Could not come up with a follow-up question',
  },
  resultError: {
    id: 'dialogue.resultError',
    defaultMessage: 'Could not come come up with a result',
  },
  complementaryLoading1: {
    id: 'dialogue.complementaryLoading1',
    defaultMessage: 'Analyzing your response..',
  },
  complementaryLoading2: {
    id: 'dialogue.complementaryLoading2',
    defaultMessage: 'Running measurements..',
  },
  complementaryLoading3: {
    id: 'dialogue.complementaryLoading3',
    defaultMessage: 'Generating follow-up question..',
  },
  resultLoading1: {
    id: 'dialogue.resultLoading1',
    defaultMessage: 'Analyzing your response..',
  },
  resultLoading2: {
    id: 'dialogue.resultLoading2',
    defaultMessage: 'Running measurements..',
  },
  resultLoading3: {
    id: 'dialogue.resultLoading3',
    defaultMessage: 'Calculating compatibility..',
  },
  resultLoading4: {
    id: 'dialogue.resultLoading4',
    defaultMessage: 'Finalizing the response..',
  },
  loadingFlow: {
    id: 'dialogue.loadingFlow',
    defaultMessage: 'Loading AI Flow by',
  },
  loaderHelpText: {
    id: 'dialogue.loaderHelpText',
    defaultMessage: 'This will take between 20-30 seconds',
  },
});
