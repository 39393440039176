import { useCallback, useState } from 'react';

import { message } from 'antd';
import { ResultScreen } from 'constants/src';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { set } from 'lodash';

import { ResultInputs } from './ResultInputs';
import { useApp } from '../../../AppContext';
import { firestore } from '../../../firebase';
import SelltoButton from '../../../subcomponents/SelltoButton';

export const AfterSubmitSection = ({ selectedLanguages }: { selectedLanguages: string[] }) => {
  const { assistant, account } = useApp();
  const [saving, setSaving] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [afterSubmit, setAfterSubmit] = useState<ResultScreen>(
    assistant?.afterSubmit || {
      title: {
        en: '',
        sv: '',
      },
      description: {
        en: '',
        sv: '',
      },
      buttonText: {
        en: '',
        sv: '',
      },
      action: 'proceed',
      url: '',
    }
  );

  const saveResults = useCallback(async () => {
    if (!account || !assistant) {
      console.error('No account/assistant');
      return;
    }

    setSaving(true); // Starta sparandets laddningsindikator

    // Använd setTimeout för att skapa en 2 sekunders fördröjning
    setTimeout(async () => {
      try {
        const assistantsCollection = collection(firestore, 'accounts', account.id, 'assistants');

        const assistantDocRef = doc(assistantsCollection, assistant.id);

        // Uppdatera endast afterSubmit-fältet
        await updateDoc(assistantDocRef, { afterSubmit });

        setUnsavedChanges(false); // Återställ tillståndet för osparade ändringar
        message.success('Thank you screen updated successfully');
      } catch (error) {
        message.error('Could not update thank you screen');
        console.error('Error saving thank you screen:', error);
      } finally {
        setSaving(false); // Stoppa sparandets laddningsindikator
      }
    }, 2000); // 2000 millisekunder = 2 sekunder
  }, [account, assistant, afterSubmit]);

  const updateResult = (
    field:
      | 'title.en'
      | 'title.sv'
      | 'description.en'
      | 'description.sv'
      | 'buttonText.en'
      | 'buttonText.sv'
      | 'action'
      | 'url',
    value: string | boolean
  ) => {
    const newResult = { ...afterSubmit };
    set(newResult, field, value);
    setAfterSubmit(newResult);
    setUnsavedChanges(true); // Ange att det finns osparade ändringar
  };

  return (
    <>
      <div className="mb-10 overflow-hidden rounded-lg border border-gray-200 bg-white">
        <div className="px-4 pt-4">
          <div>
            <div className="mb-6">
              <span className="text-md mb-0 mt-0 font-bold">After Submit</span>
              <p className="text-xms text-gray-600">
                When a lead has submitted their contact information, this info will be displayed.
              </p>
            </div>
          </div>
          <ResultInputs
            section={afterSubmit}
            updateSection={updateResult}
            type="afterSubmit"
            selectedLanguages={selectedLanguages}
          />
        </div>
        <div className="flex w-full items-center justify-end gap-4 border-t bg-white px-4 py-3">
          <div className="flex items-center justify-center gap-4">
            {unsavedChanges && <span className="text-xs text-gray-500">Remember to save your changes</span>}
            <SelltoButton onClick={saveResults} disabled={!unsavedChanges} loading={saving} type="black">
              Save
            </SelltoButton>
          </div>
        </div>
      </div>
    </>
  );
};
