export const getTitleTextSize = (textLength: number, hasInput: boolean) => {
  if (hasInput === true) {
    if (textLength >= 66) {
      return 'text-sm sm:text-base md:text-lg lg:text-xl';
    } else if (textLength >= 33) {
      return 'text-base sm:text-lg md:text-xl lg:text-2xl';
    } else {
      return 'text-lg sm:text-xl md:text-2xl lg:text-3xl';
    }
  }
  if (textLength >= 66) {
    return 'text-base sm:text-lg md:text-xl lg:text-3xl';
  } else if (textLength >= 33) {
    return 'text-lg sm:text-lg md:text-2xl lg:text-4xl';
  } else {
    return 'text-3xl sm:text-3xl md:text-4xl lg:text-5xl';
  }
};
