import { useEffect, useState } from 'react';

import { Button, Input, Modal, message } from 'antd';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  reload,
  sendEmailVerification,
  updatePassword,
  verifyBeforeUpdateEmail,
} from 'firebase/auth';

import SelltoButton from './SelltoButton';
import { auth } from '../firebase';
import { useUser } from '../UserContext';

type UpdateUserModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const UpdateUserModal = ({ isOpen, closeModal }: UpdateUserModalProps) => {
  const { user } = useUser();

  const [email, setEmail] = useState(user.email);
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const handleResendVerification = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently signed in');
      }
      await sendEmailVerification(currentUser);
      message.success('Verification email resent. Please check your inbox.');
    } catch (error) {
      console.error('Error resending verification email', error);
      if (error instanceof Error) {
        message.error(`Error resending verification email: ${error.message}`);
      } else {
        message.error(`Error resending verification email: Unknown error`);
      }
    }
  };

  const handleOk = async () => {
    try {
      const currentUser = auth.currentUser;
      console.log(auth);
      if (!currentUser || currentUser.email === null) {
        throw new Error('No user is currently signed in');
      }

      if (email !== user.email) {
        await reload(currentUser);

        if (!currentUser.emailVerified) {
          message.error('Please verify your current email before changing it.');
          return;
        }
        const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
        await reauthenticateWithCredential(currentUser, credential);

        await verifyBeforeUpdateEmail(currentUser, email);

        message.success('Email updated. Verification email sent to the new email address.');
      }

      if (newPassword && currentPassword) {
        const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
        await reauthenticateWithCredential(currentUser, credential);
        await updatePassword(currentUser, newPassword);
        message.success('Password updated successfully.');
      }

      closeModal();
    } catch (error) {
      console.error('Error updating user', error);
      if (error instanceof Error) {
        message.error(`Error updating user profile: ${error.message}`);
      } else {
        message.error(`Error updating user profile: Unknown error`);
      }
    }
  };

  useEffect(() => {
    setEmail(user.email);
    setCurrentPassword('');
    setNewPassword('');
  }, [isOpen, user.email]);

  return (
    <>
      <Modal
        title="Update your profile information"
        open={isOpen}
        // onOk={handleOk}
        footer={null}
        onCancel={closeModal}
      >
        <div className="my-2 mt-8">
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <div className="flex w-full items-center justify-center gap-2">
            <div className="h-full w-full">
              <Input
                showCount
                maxLength={30}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1"
              />
            </div>
            <Button className="items-center justify-center" type="link" onClick={handleResendVerification}>
              Resend Verification Email
            </Button>
          </div>
        </div>
        <div className="my-2">
          <label className="block text-sm font-medium text-gray-700">Current Password</label>
          <Input.Password
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="mt-1"
          />
        </div>
        <div className="my-2">
          <label className="block text-sm font-medium text-gray-700">
            New Password (leave blank to keep current password)
          </label>
          <Input.Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="mt-1" />
        </div>

        <div className="mt-6 flex w-full items-center justify-end gap-2">
          <SelltoButton onClick={closeModal}>Cancel</SelltoButton>
          <SelltoButton onClick={handleOk} type="black">
            Save
          </SelltoButton>
        </div>
      </Modal>
    </>
  );
};

export default UpdateUserModal;
