import { useCallback, useState } from 'react';

import { Form, Input, Switch, message } from 'antd';
import { collection, doc, updateDoc } from 'firebase/firestore';

import { useApp } from '../../../AppContext';
import { firestore } from '../../../firebase';
import SelltoButton from '../../../subcomponents/SelltoButton';

type AssistantInformationSectionProps = {
  aiEnabled: boolean;
  setAiEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  collectContact: boolean;
  setCollectContact: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AssistantInformationSection = ({
  aiEnabled,
  setAiEnabled,
  collectContact,
  setCollectContact,
}: AssistantInformationSectionProps) => {
  const { assistant, account, refresh } = useApp();

  const [name, setName] = useState<string>(assistant?.name || '');
  const [information, setInformation] = useState<string>(assistant?.information || '');

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveSetup = useCallback(async () => {
    if (!account || !assistant) {
      console.error('No account/assistant');
      return;
    }

    setSaving(true);

    setTimeout(async () => {
      const assistantsCollection = collection(firestore, 'accounts', account.id, 'assistants');

      const assistantDocRef = doc(assistantsCollection, assistant.id);

      try {
        await updateDoc(assistantDocRef, { name, information, aiEnabled, collectContact });
        setUnsavedChanges(false);
        message.success('Assistant updated successfully');
        await refresh();
      } catch (error) {
        message.error('Could not update assistant');
      } finally {
        setSaving(false);
      }
    }, 1500); // 1500 millisekunder = 1.5 sekunder
  }, [account, assistant, name, information, aiEnabled, collectContact, refresh]);

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setter(e.target.value);
      setUnsavedChanges(true);
    };

  const { TextArea } = Input;

  return (
    <>
      <div className="max-screen-lg mb-10 overflow-hidden rounded-lg border border-gray-200 bg-white">
        <div className="p-4">
          <div className="mb-4">
            <span className="text-md text-md mb-1 mt-0 font-bold ">Nurturing data</span>
            <p className=" text-xms text-gray-600">
              This input helps the AI to understand your business and the way it calculates the score
            </p>
          </div>
          <Form>
            <div className="mb-4">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Assistant name
                <p className="mb-2 text-xs font-normal text-gray-600">
                  This name is only for internal usage and recognition. You can name your assistant anything without it
                  changing your response.
                </p>
              </label>
              <Input
                showCount
                maxLength={30}
                id="name"
                value={name}
                size="middle"
                className="rounded-md border-gray-300"
                placeholder="eg. E-commerce clients"
                onChange={handleInputChange(setName)}
              />
            </div>
            <Form.Item className={`mt-4 flex w-full justify-between`}>
              <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
                <div className="flex items-center justify-start gap-2 font-medium">
                  <span>Collect contact information</span>
                </div>
                <p className="mb-2.5 text-xs font-normal text-gray-600">
                  Wether or not you want your form to collect the users contact information
                </p>
              </label>
              <Switch
                checked={collectContact}
                onChange={() => {
                  setUnsavedChanges(true);
                  setCollectContact((v) => !v);
                }}
                style={{ backgroundColor: collectContact ? '#1890ff' : '#828282' }}
                className="border"
              />
            </Form.Item>
            <Form.Item className={`mt-4 flex w-full justify-between`}>
              <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
                <div className="flex items-center justify-start gap-2 font-medium">
                  <span>Enable AI Features</span>
                </div>
                <p className="mb-2.5 text-xs font-normal text-gray-600">
                  When enabled, our AI will ask follow-up questions to the visitor to better understand their needs and
                  do a score calculation based on their fit with your company
                </p>
              </label>
              <Switch
                checked={aiEnabled}
                onChange={() => {
                  setUnsavedChanges(true);
                  setAiEnabled((v) => !v);
                }}
                style={{ backgroundColor: aiEnabled ? '#1890ff' : '#828282' }}
                className="border"
              />
            </Form.Item>

            {aiEnabled && (
              <>
                <div className="flex justify-between py-2">
                  <div>
                    <label className="block text-sm font-medium leading-6 text-gray-900" htmlFor="information">
                      AI Data
                      <p className="text-xs font-normal text-gray-600">
                        Nurture your flows AI with data about your business. Best practice is to include information
                        about your ICP, some content about your product and some examples on how you have worked with
                        customers previously
                      </p>
                    </label>
                  </div>
                </div>
                <TextArea
                  autoSize={{
                    minRows: 2,
                    maxRows: 8,
                  }}
                  id="information"
                  size="middle"
                  className="rounded-md border-gray-300"
                  placeholder="Write as much information about what the assistant is selling here"
                  value={information}
                  onChange={handleInputChange(setInformation)}
                  showCount
                  maxLength={50000}
                  style={{
                    overflow: 'hidden',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                  }}
                />
              </>
            )}
            <style>{`#information::-webkit-scrollbar { display: none; }`}</style>
          </Form>
        </div>
        <div className="flex w-full items-center justify-end gap-4 border-t bg-white px-4 py-3">
          {unsavedChanges && <span className="text-xs text-gray-500">Remember to save your changes</span>}
          <SelltoButton onClick={saveSetup} type="black" disabled={!unsavedChanges} loading={saving}>
            Save
          </SelltoButton>
        </div>
      </div>
    </>
  );
};
