import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasJustRegistered, setHasJustRegistered] = useState(false);

  const value = {
    user,
    setUser,
    setLoading,
    loading,
    hasJustRegistered,
    setHasJustRegistered,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
