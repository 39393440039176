import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  buttonText: {
    id: 'dialogue.questionScreen.buttonText',
    defaultMessage: 'Continue',
  },
  helperTextContinue: {
    id: 'dialogue.questionScreen.helperTextContinue',
    defaultMessage: `Press Enter`,
  },
  helperTextAnykey: {
    id: 'dialogue.questionScreen.helperTextAnykey',
    defaultMessage: 'Press any key to start typing',
  },
});
