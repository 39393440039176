import { createRoot } from 'react-dom/client';

import App from './App';
import './styles/customstyles.css';
import './styles/generated.css';
import './styles/sora.css';
import './styles/tailwind.css';

const root = document.getElementById('root');
if (!root) throw new Error('Root element not found');
createRoot(root).render(<App />);
