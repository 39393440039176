import { useCallback } from 'react';

import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';

import { DialogueButton } from './DialogueButton';
import { useKeyDown } from '../../utils/useKeyDown';

import { messages } from './ExplanationScreen.text';

type ExplanationScreenProps = {
  text: string;
  buttonText: string;
  onClick: () => void;
};

export const ExplanationScreen = ({ buttonText, onClick, text }: ExplanationScreenProps) => {
  const { formatMessage } = useIntl();
  const handleKeyPress = useCallback(() => {
    onClick();
  }, [onClick]);

  useKeyDown(handleKeyPress, 'Enter', { initialDelay: 100 });

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.3 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.7 }}
      transition={{ duration: 0.3 }}
      className="align-center relative flex h-full w-full flex-col items-center justify-center"
    >
      <div className="text-on-background text-center text-4xl font-semibold">{formatMessage(messages.title)}</div>
      <div className="bg-surface my-4 w-full overflow-hidden rounded-3xl sm:my-8 md:my-10 ">
        <div className="text-on-surface h-full w-full overflow-y-auto px-4 py-4 text-lg font-medium leading-relaxed sm:p-6 sm:text-xl md:p-8 md:text-2xl lg:p-10 lg:text-3xl lg:leading-10">
          {text}
        </div>
      </div>

      <DialogueButton buttonText={buttonText} onClick={onClick} helperText={formatMessage(messages.helperText)} />
    </motion.div>
  );
};
