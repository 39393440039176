import { useCallback, useMemo } from 'react';

import { motion } from 'framer-motion';
import { useIntl } from 'react-intl';

import { DialogueButton } from './DialogueButton';
import { useKeyDown } from '../../utils/useKeyDown';
import { getTitleTextSize } from '../utils/textSizing';

import { messages } from './MessageScreen.text';

type MessageScreenProps = {
  text: string;
  buttonText: string;
  onClick: () => void;
};

/** Used for WelcomeMessage and ResultMessage steps */
export const MessageScreen = ({ text, buttonText, onClick }: MessageScreenProps) => {
  const { formatMessage } = useIntl();

  const handleKeyPress = useCallback(() => {
    onClick();
  }, [onClick]);

  useKeyDown(handleKeyPress, 'Enter', { initialDelay: 100 });

  const textSize = useMemo(() => getTitleTextSize(text.length, false), [text]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.3 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.7 }}
      transition={{ duration: 0.3 }}
      className="align-center item-center relative flex h-full w-full flex-col justify-center"
    >
      <span className={`${textSize} text-on-background mb-5 break-words text-center font-medium leading-[140%]`}>
        {text}
      </span>
      <DialogueButton buttonText={buttonText} onClick={onClick} helperText={formatMessage(messages.helperText)} />
    </motion.div>
  );
};
