// App Wrapper

import { useEffect } from 'react';

import { Outlet, useParams } from 'react-router-dom';

import { useApp } from './AppContext';
import MenuSidebar from './MenuSidebar';

const Layout = () => {
  const { assistantId } = useParams();

  const { selectAssistant } = useApp();

  useEffect(() => {
    if (!assistantId) return;
    selectAssistant(assistantId);
  }, [selectAssistant, assistantId]);

  return (
    <div className="">
      <div className="fixed top-0 z-50 flex h-14 w-full justify-between border-b border-b-neutral-200 bg-white p-2 px-4">
        <img
          src="https://uploads-ssl.webflow.com/651405aa325a21e5721c392d/65140a78aec012105e2aef5f_Sellto%20ai.svg"
          alt="Logo"
          className="ml-1.5"
          style={{ maxWidth: '100px', width: '100%' }}
        />
      </div>
      <div className="sellto-color-bg flex h-screen pt-14">
        <div className="w-auto border-r border-neutral-200">
          <MenuSidebar />
        </div>
        <div className="flex-grow overflow-y-auto">
          <main className="w-full px-6">
            <div className="p-4 pt-5">
              <div className="p-4">
                <div className="ml-auto mr-auto max-w-7xl">
                  <Outlet />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
