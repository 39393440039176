import { useCallback, useEffect, useState } from 'react';

import { ConfigProvider, theme } from 'antd';
import type {
  AnswerRequest,
  AnswerResponse,
  ComplementRequest,
  ComplementResponse,
  UpdateContactRequest,
} from 'api/src/routes/dialogue';
import { ComplementaryInput, Dialogue, ResultInput, UpdateContactInput } from 'components';
import { ErrorScreen } from 'components/src/Dialogue/components/ErrorScreen';
import { SelltoLogotype } from 'components/src/Icons/SelltoLogotype';
import { DialogueData } from 'constants/src';
import { AnimatePresence, motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

export const Answer = () => {
  const { accountId, assistantId } = useParams();
  const [dialogueData, setDialogueData] = useState<DialogueData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  const loadBot = useCallback(async () => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_URL}/dialogue/get?accountId=${accountId}&assistantId=${assistantId}`;

      const response = await fetch(url);
      if (!response.ok) {
        console.log('Erroring out here');

        throw new Error('Could not load assistant');
      }
      const data = (await response.json()) as DialogueData;
      setDialogueData(data);
      setError(null);
    } catch (err) {
      console.log({ err });
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [accountId, assistantId]);

  useEffect(() => {
    loadBot();
  }, [loadBot]);

  /** This function will throw uncaught errors */
  const getComplementary = async (values: ComplementaryInput) => {
    if (!dialogueData) {
      throw new Error('Bot not loaded yet');
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/dialogue/complementaryQuestion`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
        assistantId,
        source: 'url',
        ...values,
      } as ComplementRequest),
    });
    if (!response.ok) {
      throw new Error('Could not load complementary question');
    }
    const data = (await response.json()) as ComplementResponse;
    return data;
  };

  /** This function will throw uncaught errors */
  const getResults = async (values: ResultInput) => {
    if (!dialogueData) {
      throw new Error('Bot not loaded yet');
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/dialogue/score`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
        assistantId,
        ...values,
      } as AnswerRequest),
    });
    const data = (await response.json()) as AnswerResponse;
    return data;
  };

  /** This function will throw uncaught errors */
  const updateContact = async (values: UpdateContactInput) => {
    console.log('updateContact start', values);
    if (!dialogueData) {
      throw new Error('Bot not loaded yet');
    }
    await fetch(`${process.env.REACT_APP_API_URL}/dialogue/updateContact`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        accountId,
        assistantId,
        ...values,
      } as UpdateContactRequest),
    });
  };

  const { darkAlgorithm } = theme;
  // This is just so that we can easily keep the content itself
  // separated so we always render the ConfigProvider
  function renderContent() {
    if (loading) {
      return (
        <motion.div
          key="loading"
          className="relative flex h-screen w-full items-center justify-center text-white transition-all duration-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="inline-flex items-center justify-center gap-2">
            <div className="font-['Inter'] text-sm font-normal  text-white">Loading AI Flow by</div>
            <SelltoLogotype />
            <svg
              className="h-5 w-5 animate-spin text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </motion.div>
      );
    }
    if (error || !dialogueData) {
      return (
        <motion.div
          key="error"
          className="z-20 mx-6 mb-5 flex h-screen w-full max-w-lg items-center justify-center transition-all duration-300 sm:mx-10 sm:max-w-xl md:max-w-2xl lg:max-w-5xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ErrorScreen
            title="Could not find Dialogue"
            showIcon
            onClick={() => window.location.reload()}
            buttonText="Reload"
            description="The page you are looking for could not be found."
          />
        </motion.div>
      );
    }
    return (
      <>
        <Helmet>
          {/* TODO: Add organisation name here instead */}
          <title>{dialogueData.companyName}</title>
        </Helmet>
        <motion.div
          key="error"
          className="flex h-[100svh] w-full items-center justify-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onTouchMove={(e) => {
            e.preventDefault();
          }}
        >
          <Dialogue
            data={dialogueData}
            getComplementary={getComplementary}
            getResults={getResults}
            updateContact={updateContact}
          />
        </motion.div>
      </>
    );
  }

  return (
    <ConfigProvider theme={{ algorithm: darkAlgorithm }}>
      <div
        id="sellto-ai-flow"
        className="flex h-screen items-center justify-center bg-black text-white"
        onTouchMove={(e) => {
          e.preventDefault();
        }}
      >
        <AnimatePresence mode="popLayout" initial={false}>
          {renderContent()}
        </AnimatePresence>
      </div>
    </ConfigProvider>
  );
};
