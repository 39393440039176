import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUser } from './UserContext';

type Props = {
  /** Internal link */
  loggedInLink: string;
  /** Internal link
   * @example
   * // Navigates back to the login page
   * /login */
  loggedOutLink: string;
};

/** This component is used only as a redirect that is dependant
 * on the user login state. All redirects are internal
 * at the moment, so you can't link to an external page */
export const AuthRedirectRoute = ({ loggedInLink, loggedOutLink }: Props) => {
  const navigate = useNavigate();
  const { user, loading } = useUser();

  useEffect(() => {
    if (loading) return;
    if (user) {
      navigate(loggedInLink);
    } else {
      navigate(loggedOutLink);
    }
  }, [navigate, user, loggedInLink, loggedOutLink, loading]);

  return null;
};
