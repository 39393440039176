import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Account, Assistant, User } from 'constants/src';
import isEqual from 'deep-equal';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

import { firestore } from './firebase';
import { useUser } from './UserContext';

type AppContextType = {
  account: WithId<Account> | null;
  /** Currently selected assistant */
  assistant: WithId<Assistant> | null;
  assistants: WithId<Assistant>[];
  loading: boolean;
  error: string | null;
  /** Reloads the account and all assistants */
  refresh: () => Promise<void>;
  selectAssistant: (id: string) => void;
};

type WithId<T> = T & { id: string };

const AppContext = createContext<AppContextType>({
  account: null,
  assistant: null,
  assistants: [],
  loading: false,
  error: null,
  refresh: async () => {},
  selectAssistant: () => {},
});

export const useApp = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider');
  }
  return context;
};

export const AppProvider = ({ children }: { children: JSX.Element }) => {
  const { user } = useUser();

  const [account, setAccount] = useState<WithId<Account> | null>(null);
  const [assistant, setAssistant] = useState<WithId<Assistant> | null>(null);
  const [assistants, setAssistants] = useState<WithId<Assistant>[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const prevUserRef = useRef<User | null>(null);

  const loadApp = useCallback(async () => {
    if (!user) {
      console.log("Could not load Account/Assistant, user wasn't logged in");
      return;
    }
    setLoading(true);
    try {
      console.log({ user });
      console.log('Loading accounts for accountId: ', user.accountId);
      const accountDocRef = doc(firestore, 'accounts', user.accountId);
      const accountDoc = await getDoc(accountDocRef);
      const accData = accountDoc.data() as Account;

      if (accountDoc.exists()) {
        const newAccount = {
          id: accountDoc.id,
          ...accData,
        };
        if (!isEqual(account, newAccount)) {
          setAccount(newAccount);
        }
      }

      // Get the first item from the assistantsCollection
      // and set it to the setAssistant
      const assistantsCollection = collection(accountDocRef, 'assistants');
      const querySnapshot = await getDocs(assistantsCollection);
      const allAssistants: (Assistant & { id: string })[] = [];
      querySnapshot.forEach((document) => {
        const assData = document.data() as Assistant;
        const newAssistant = {
          id: document.id,
          ...assData,
        };
        allAssistants.push(newAssistant);
      });
      // sort allAssistants based on createdAt
      allAssistants.sort(
        (a, b) =>
          // @ts-ignore - createdAt is an object with seconds and nanoseconds
          a.createdAt?.seconds - b.createdAt?.seconds
      );
      setAssistant((v) => (isEqual(allAssistants[0], v) ? v : allAssistants[0]));
      if (!isEqual(assistants, allAssistants)) {
        setAssistants(allAssistants);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error while retrieving account data');
      console.log(err);
      console.error('Something went wrong while fetching account or assistant');
    } finally {
      setLoading(false);
    }
  }, [account, assistants, user]);

  useEffect(() => {
    loadApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectAssistant = useCallback(
    async (id: string) => {
      let selectedAssistant = assistants.find((a) => a.id === id);
      if (selectedAssistant) {
        setAssistant(selectedAssistant);
        return;
      } else {
        if (!account) {
          setError('No account found while selecting assistant');
          return;
        }
        try {
          await loadApp();
          selectedAssistant = assistants.find((a) => a.id === id);
          if (selectedAssistant) {
            setAssistant(selectedAssistant);
            return;
          }
        } catch (err) {
          setError('Could not find the Assistant you were looking for');
        }

        setError('Could not find the Assistant you were looking for');

        // Refetch all assistants.
        // If still not found, we set error
      }
    },
    [account, assistants, loadApp]
  );

  const value: AppContextType = {
    account,
    assistant,
    assistants,
    loading,
    error,
    selectAssistant,
    refresh: loadApp,
  };

  // Clear on logout
  useEffect(() => {
    if (!user) {
      setError(null);
      setAccount(null);
      setAssistant(null);
      setAssistants([]);
    }
  }, [user]);

  // Load app on login
  useEffect(() => {
    const prevUser = prevUserRef.current;
    if (!prevUser && user) {
      loadApp();
    }
    prevUserRef.current = user;
  }, [loadApp, user]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
