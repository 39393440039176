import { useCallback } from 'react';

import { EmojiSadIcon } from '@heroicons/react/outline';
import { motion } from 'framer-motion';

import { DialogueButton } from './DialogueButton';
import { useKeyDown } from '../../utils/useKeyDown';

type ErrorScreenPops = {
  showIcon?: boolean;
  title: string;
  buttonText?: string;
  onClick?: () => void;
  description?: string | JSX.Element;
};

export const ErrorScreen = ({ title, buttonText, onClick, showIcon, description }: ErrorScreenPops) => {
  const handleKeyPress = useCallback(() => {
    onClick?.();
  }, [onClick]);

  useKeyDown(handleKeyPress, 'Enter', { initialDelay: 100 });

  return (
    <motion.div
      initial={{ opacity: 0, y: '1rem' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '1rem' }}
      className="align-center relative flex h-full w-full flex-col items-center justify-center"
    >
      {showIcon && <EmojiSadIcon className="text-on-background mb-10 h-20 w-20" aria-hidden="true" />}
      <span className="mb-5 break-words text-center text-3xl font-medium leading-[115%] sm:text-4xl md:text-4xl lg:text-5xl">
        {title}
      </span>
      {description && <span className="text-on-background mb-10 text-center text-xl font-normal">{description}</span>}
      {onClick && <DialogueButton buttonText={buttonText} onClick={onClick} />}
    </motion.div>
  );
};
