import { useState } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Switch, message } from 'antd';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { CopyBlock, dracula } from 'react-code-blocks';

import { useApp } from '../../../AppContext';
import { firestore } from '../../../firebase';
import SelltoButton from '../../../subcomponents/SelltoButton';

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

export const TriggerSection = () => {
  const { account, assistant, refresh } = useApp();

  const [enableTrigger, setEnableTrigger] = useState(
    // Set to true if assistant.triggerSettings.delay is not null or undefined
    assistant?.triggerSettings?.delay !== null && assistant?.triggerSettings?.delay !== undefined
  );
  const [triggerDelay, setTriggerDelay] = useState(assistant?.triggerSettings?.delay || 0);
  const [triggerPaths, setTriggerPaths] = useState(assistant?.triggerSettings?.paths || []);

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveSetup = async () => {
    if (!account || !assistant) {
      console.error('No account/assistant');
      return;
    }

    setSaving(true);

    // We add a timeout here just to make sure that
    // users understands that something happens
    setTimeout(async () => {
      const assistantsCollection = collection(firestore, 'accounts', account.id, 'assistants');

      const assistantDocRef = doc(assistantsCollection, assistant.id);

      try {
        await updateDoc(assistantDocRef, {
          triggerSettings: {
            delay: enableTrigger ? triggerDelay : null,
            paths: triggerPaths,
          },
        });
        setUnsavedChanges(false);
        message.success('Assistant updated successfully');
        refresh();
      } catch (error) {
        message.error('Could not update assistant');
      } finally {
        setSaving(false);
      }
    }, 1500); // 1500 millisekunder = 1.5 sekunder
  };

  const currentDomain = window.location.origin;

  return (
    <>
      <div className="max-screen-lg mb-10 overflow-hidden rounded-lg border border-gray-200 bg-white">
        <div className="p-4">
          <div className="mb-4">
            <span className="text-md text-md mb-1 mt-0 font-bold ">Widget settings & Implementation</span>
            <p className=" text-xms text-gray-600">
              These settings relate to how the widget should be triggered as well as how to implement the widget on your
              website.
            </p>
          </div>
          <Form.Item className={`mt-4 flex w-full justify-between`}>
            <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
              <div className="flex items-center justify-start gap-2 font-medium">
                <span>Enable Auto-trigger</span>
              </div>
              <p className="mb-2.5 text-xs font-normal text-gray-600">
                Control wether the widget should automatically appear after a set amount of time.
              </p>
            </label>
            <Switch
              checked={enableTrigger}
              onChange={() => {
                setUnsavedChanges(true);
                setEnableTrigger((v) => !v);
                // We default to 15seconds when the user first enables the
                // auto-trigger, since 0 seconds is a bit short for the default
                if (triggerDelay === 0 && assistant?.triggerSettings?.delay === null) {
                  setTriggerDelay(10);
                }
              }}
              style={{ backgroundColor: enableTrigger ? '#1890ff' : '#828282' }}
              className="border"
            />
          </Form.Item>
          <Form.Item className={`mt-4 flex w-full justify-between ${!enableTrigger ? 'opacity-50' : ''}`}>
            <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
              <div className="flex items-center justify-start gap-2 font-medium">
                <span>Auto-trigger delay</span>
              </div>
              <p className="mb-2.5 text-xs font-normal text-gray-600">
                Set how many seconds we should wait before the widget appears on the site. Setting it to 0 will make it
                appear instantly.
              </p>
            </label>
            <InputNumber
              disabled={!enableTrigger}
              size="large"
              value={triggerDelay}
              min={0}
              max={600}
              onChange={(v) => {
                setUnsavedChanges(true);
                setTriggerDelay(v || 0);
              }}
            />
          </Form.Item>
          <Form.Item className={`mt-4 flex w-full justify-between ${!enableTrigger ? 'opacity-50' : ''}`}>
            <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
              <div className="flex items-center justify-start gap-2 font-medium">
                <span>Auto-trigger paths</span>
              </div>
              <p className="mb-2.5 text-xs font-normal text-gray-600">
                Control on which paths the widget should automatically trigger. For example you might only want the
                widget to appear on the <code className="bg-gray-200">/evaluate</code> page instead of appearing on all
                pages on your site. You can use the <code className="bg-gray-200">*</code> wildcard to make the widget
                appear on all paths. For example to make it appear on all paths under{' '}
                <code className="bg-gray-200">/post</code> you would add <code className="bg-gray-200">post/*</code>
              </p>
            </label>
            <Form
              name="paths_form"
              {...formItemLayout}
              onFieldsChange={(_changedFields, allFields) => {
                // We retrieve the parent field, since the value on that field is an array of all nested fields
                const firstField = allFields.find((f) => f.name.length === 1);
                const newAllPaths = firstField?.value;
                setUnsavedChanges(true);
                setTriggerPaths(newAllPaths);
              }}
            >
              <Form.List name="paths" initialValue={triggerPaths}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Form.Item {...formItemLayout} required={false} key={field.key}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              warningOnly: true,
                              message:
                                'Leaving this empty will make the widget appear on the base url. Remove all input fields if you want the widget to appear on all paths',
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            disabled={!enableTrigger}
                            showCount
                            size="middle"
                            className="rounded-md border-gray-300"
                            addonBefore={`https://${account?.domain}/`}
                            placeholder="tour"
                            style={{ width: '400px' }}
                          />
                        </Form.Item>

                        <Button
                          type="link"
                          onClick={() => remove(field.name)}
                          disabled={!enableTrigger}
                          icon={<MinusCircleOutlined />}
                          className="mx-2 text-gray-400 hover:text-gray-500"
                        />
                      </Form.Item>
                    ))}
                    <Button
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                      style={{ width: '400px' }}
                      disabled={!enableTrigger}
                    >
                      Add new path
                    </Button>
                  </>
                )}
              </Form.List>
            </Form>
          </Form.Item>

          <div className={`mt-4 flex w-full justify-between`}>
            <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
              <div className="flex items-center justify-start gap-2 font-medium">
                <span>Widget implementation instructions</span>
              </div>
              <p className="mb-2.5 text-xs font-normal text-gray-600">
                To embed the widget on your site you need to add the following code snippet to your site at the end of
                the <code>{'<body>'}</code> tag
              </p>
              <CopyBlock
                codeContainerStyle={{ cursor: 'text', fontSize: '0.8rem' }}
                text={`<!-- Sellto AI Widget -->
<script defer>
  window.SELLTO_AI_CONFIG = {
    accountId: '${account?.id}',
    flowIds: ['${assistant?.id}'],
    zIndex: 10000,
  };
  var cssLink = document.createElement('link');
  cssLink.rel = 'stylesheet';
  cssLink.type = 'text/css';
  cssLink.media = 'all';
  cssLink.href = '${currentDomain}/embed/index.css';
  document.head.appendChild(cssLink);
</script>
<script defer src='${currentDomain}/embed/index.js'></script>
<!-- End Sellto AI Widget -->`}
                language="html"
                theme={dracula}
                codeBlock
              />
            </label>
          </div>

          <div className={`mt-4 flex w-full justify-between`}>
            <label htmlFor="heading" className="block text-sm font-medium leading-6 text-gray-900">
              <div className="flex items-center justify-start gap-2 font-medium">
                <span>Button trigger implementation instructions</span>
              </div>
              <p className="mb-2.5 text-xs font-normal text-gray-600">
                To make the widget open on a button click add the following attributes to your button
                <p>
                  <b>NOTE:</b> The Widget implementation still needs to be done for the button trigger to work
                </p>
              </p>
              <CopyBlock
                codeContainerStyle={{ cursor: 'text', fontSize: '0.8rem' }}
                text={`data-sellto-flow-id="${assistant?.id}"`}
                language="javascript"
                theme={dracula}
              />
            </label>
          </div>
        </div>

        <div className="flex w-full items-center justify-end gap-4 border-t bg-white px-4 py-3">
          {unsavedChanges && <span className="text-xs text-gray-500">Remember to save your changes</span>}
          <SelltoButton onClick={saveSetup} type="black" disabled={!unsavedChanges} loading={saving}>
            Save
          </SelltoButton>
        </div>
      </div>
    </>
  );
};
