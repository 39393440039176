type DialogueButtonProps = {
  buttonText?: string;
  onClick: () => void;
  /** The text thats displayed under the button */
  helperText?: string;
};

export const DialogueButton = ({ onClick, buttonText, helperText }: DialogueButtonProps) => {
  return (
    <div className="inline-flex flex-col items-center justify-center">
      {!!buttonText && (
        <button
          type="button"
          className="text-md bg-primary hover:bg-primary-variant focus:bg-primary-variant mt-4 inline-flex items-center justify-center rounded-2xl px-4 py-2 transition-all focus:border-none focus:outline-none focus:ring-0"
          onClick={onClick}
        >
          <div className="text-on-primary text-center text-lg font-medium">{buttonText}</div>
        </button>
      )}
      {!!helperText && (
        <span className="text-on-background mt-2 flex hidden items-center justify-center gap-1 whitespace-pre text-center font-medium sm:block">
          {helperText.toLowerCase().includes('enter') ? (
            <>
              {helperText}
              <kbd className="font-sans">⏎</kbd>
            </>
          ) : (
            <>{helperText}</>
          )}
        </span>
      )}
    </div>
  );
};
