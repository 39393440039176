import { useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { Oval } from 'react-loader-spinner';

type LoaderProps = {
  messages: string[];
  /** How many ms we should wait before changing to the next message */
  changeTime: number;
  loop?: boolean;
  loaderHelpText?: string;
};

export const Loader = ({ messages, changeTime, loop, loaderHelpText }: LoaderProps) => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (loop) {
        setMessageIndex((prev) => (prev + 1) % messages.length);
      } else {
        setMessageIndex((prev) => Math.min(prev + 1, messages.length - 1));
      }
    }, changeTime);

    return () => clearInterval(interval);
  }, [changeTime, loop, messages.length]);

  const message = messages[messageIndex];

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.3 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.7 }}
      transition={{ duration: 0.3 }}
      className="align-center relative flex h-full w-full flex-col items-center justify-center text-center"
    >
      <Oval
        height={50}
        width={50}
        color="var(--color-on-background)"
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="var(--color-on-surface)"
        strokeWidth={5}
        strokeWidthSecondary={5}
      />
      <AnimatePresence mode="wait">
        <motion.div
          key={message}
          initial={{ opacity: 0, y: '-1rem' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '1rem' }}
          transition={{ duration: 0.2 }}
          className="text-on-background mt-20 text-center text-2xl font-normal sm:text-2xl md:text-4xl lg:text-5xl"
          style={{ lineHeight: '120%' }}
        >
          {message}
        </motion.div>
      </AnimatePresence>
      <span className="mt-3">{loaderHelpText}</span>
    </motion.div>
  );
};
