import { Fragment, useEffect, useState } from 'react';

import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Link as RouterLink } from 'react-router-dom';

import { auth, firestore } from './firebase';
import PasswordResetModal from './subcomponents/PasswordResetModal';
import { useUser } from './UserContext';

const Spinner = () => {
  return (
    <svg className="h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const Login = () => {
  const { setUser } = useUser(); // Hämta setUser från useUser

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    success: false,
    message: '',
  });

  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);
  const openPasswordResetModal = () => setIsPasswordResetModalOpen(true);
  const closePasswordResetModal = () => setIsPasswordResetModalOpen(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userDocRef = doc(firestore, 'users', user.uid);

      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUser(userDoc.data(), userDoc.id); // Uppdatera din globala användarstate med userDocId
        if (userDoc.data().email !== userCredential.user.email) {
          updateDoc(userDocRef, { email: userCredential.user.email });
        }
      }

      setLoading(false);
      setToast({
        show: true,
        success: true,
        message: `Signed in as ${email}`,
      });
    } catch (error) {
      setLoading(false);
      setToast({
        show: true,
        success: false,
        message: `Error signing in: ${error.message}`,
      });
    }
  };

  useEffect(() => {
    if (toast.show && toast.success) {
      const timer = setTimeout(() => {
        setToast({ show: false, success: false, message: '' });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [toast]);

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="relative flex min-h-screen w-1/2 items-center justify-center space-y-8 lg:w-1/2">
        <img
          className="absolute left-10 top-10 mx-auto h-8 w-auto"
          src="https://uploads-ssl.webflow.com/651405aa325a21e5721c392d/65249447a54c89915812f134_icon-sellto.svg"
          alt="Sellto Logo"
        />
        <div className="ml-auto mr-auto max-w-xs">
          <div>
            <div className="w-full text-left">
              <h2 className="mb-3 mt-6 text-3xl font-semibold text-gray-900">Log in</h2>
              <p className="text-gray-500">
                To log in to your account use the email and password you used when you signed up to Sellto
              </p>
            </div>
          </div>

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-200 px-3 py-2 text-gray-700 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-200 px-3 py-2 text-gray-700 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>
              <div className="text-sm">
                <RouterLink onClick={openPasswordResetModal} className="font-medium text-blue-600 hover:text-blue-500">
                  Forgot your password?
                </RouterLink>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="text-md group relative mb-4 flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-4 font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                disabled={loading}
              >
                {loading ? <Spinner /> : 'Sign in'}
              </button>
            </div>
          </form>
          <p className="mt-2 text-center text-sm text-gray-600">
            Not a member?{' '}
            <a href="/create-account" className="font-medium text-blue-600 hover:text-blue-500">
              Request access
            </a>
          </p>
        </div>
      </div>
      <div
        className="hidden h-screen lg:block lg:w-1/2"
        style={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1692037198805-c7421032c5cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3087&q=80)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end justify-center px-4 py-6 sm:items-end sm:p-4"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={toast.show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {toast.success ? (
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    ) : (
                      <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{toast.message}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 focus:text-gray-500 focus:outline-none"
                      onClick={() => {
                        setToast({ show: false, success: false, message: '' });
                      }}
                    >
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <PasswordResetModal isOpen={isPasswordResetModalOpen} closeModal={closePasswordResetModal} />
    </div>
  );
};

export default Login;
