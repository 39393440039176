import { useRef, useState } from 'react';

import { Locale } from 'constants/src';
import { AnimatePresence, motion } from 'framer-motion';
import { FaAngleDown, FaGlobeAfrica } from 'react-icons/fa';
import { useIntl } from 'react-intl';

import { useClickOutside } from '../../utils/useClickOutside';

import { messages } from './LanguageToggle.text';

type Props = {
  locale: Locale;
  setLocale: (language: Locale) => void;
  supportedLocales: { label: string; value: Locale }[];
};

export const LanguageToggle = ({ locale, setLocale, supportedLocales }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();

  useClickOutside(containerRef, () => setIsOpen(false));

  const toggleLanguage = (language: 'en' | 'sv') => {
    setLocale(language);
    setIsOpen(false);
  };

  return (
    <div className="relative block text-left" ref={containerRef}>
      <button
        type="button"
        className="focus-none bg-primary hover:bg-primary-variant text-on-primary inline-flex w-full items-center justify-center justify-center gap-2 rounded-full px-3 py-2 pl-3 text-sm font-medium transition-all duration-300 focus:outline-none focus:ring-0"
        onClick={() => setIsOpen(!isOpen)}
      >
        <FaGlobeAfrica height={18} width={18} className="shrink-0" />
        {/* We specify bg-transparent here since some client websites have a bg color on the overflow-hidden class */}
        <span className="shrink overflow-hidden text-clip whitespace-nowrap bg-transparent">
          {formatMessage(messages.language)}
        </span>
        <FaAngleDown style={{ transform: `rotate(${isOpen ? '180deg' : '0deg'})` }} className="transition-all" />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="bg-primary absolute left-0 right-0 mt-2 origin-top-left overflow-hidden rounded-md bg-opacity-10 shadow-lg ring-1 ring-black ring-opacity-5"
            initial={{ y: -25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -25, opacity: 0 }}
          >
            <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {supportedLocales.map((l) => (
                <button
                  type="button"
                  key={l.value}
                  className={`hover:bg-primary text-on-primary hover:bg-primary-variant block w-full px-4 py-2 text-left text-sm transition-all ${
                    locale === l.value ? 'font-bold' : ''
                  }`}
                  onClick={() => toggleLanguage(l.value)}
                >
                  {l.label}
                </button>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
