import { Fragment, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { IconPlugConnectedX } from '@tabler/icons-react';
import { HiChevronUpDown } from 'react-icons/hi2';
import { IoCheckmarkSharp } from 'react-icons/io5';
import {
  PiChartPieSliceFill,
  PiCreditCardBold,
  PiCreditCardFill,
  PiGearSixBold,
  PiGearSixFill,
  PiSmileyWinkBold,
  PiSmileyWinkFill,
  PiSparkleBold,
  PiSparkleFill,
  PiUsersBold,
  PiUsersFill,
} from 'react-icons/pi';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useApp } from './AppContext';
import ProfileSettingsDropdown from './subcomponents/ProfileSettingsDropdown';

const iconStyle = { fontSize: '17px' };

const companyItems = [
  {
    name: 'Members',
    icon: <PiUsersFill style={iconStyle} />,
    filledIcon: <PiUsersBold style={iconStyle} />,
    path: '/members',
    visible: true,
    upcomingFeature: true,
  },
  {
    name: 'Billing',
    icon: <PiCreditCardFill style={iconStyle} />,
    filledIcon: <PiCreditCardBold style={iconStyle} />,
    path: '/hey',
    upcomingFeature: true,
    visible: false,
  },
  {
    name: 'Settings',
    icon: <PiGearSixFill style={iconStyle} />,
    filledIcon: <PiGearSixBold style={iconStyle} />,
    path: '/settings',
    visible: true,
    upcomingFeature: false,
  },
];

const menuItems = [
  {
    name: 'Leads',
    icon: <PiSmileyWinkFill style={iconStyle} />,
    filledIcon: <PiSmileyWinkBold style={iconStyle} />,
    path: '/leads',
  },
  {
    name: 'Configuration',
    icon: <PiSparkleFill style={iconStyle} />,
    filledIcon: <PiSparkleBold style={iconStyle} />,
    path: '/edit',
  },
  {
    name: 'Analytics',
    icon: <PiChartPieSliceFill style={iconStyle} />,
    filledIcon: <PiChartPieSliceFill style={iconStyle} />,
    path: '/',
    upcomingFeature: true,
  },
  {
    name: 'Connections',
    icon: <IconPlugConnectedX style={iconStyle} size={18} />,
    filledIcon: <IconPlugConnectedX style={iconStyle} size={18} />,
    path: '/powerups',
  },
];

const MenuSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { assistants, account } = useApp();

  // Uppdaterad state-variabel för att undvika namnkonflikter
  const [currentAssistant, setCurrentAssistant] = useState(assistants[0]);

  const handleSelectChange = (assistant: any) => {
    setCurrentAssistant(assistant);
    const newPath = `/${account?.id}/${assistant.id}/leads`;
    navigate(newPath);
  };

  if (!currentAssistant) {
    // This will never happen since it will block the render earlier.
    // This is just for type safety
    return null;
  }

  return (
    <div
      id="sidebar"
      className="border-r-1 relative z-10 flex h-full w-[275px] min-w-[240px] flex-col justify-between overflow-y-auto border-black px-4 pb-6 pt-6"
      aria-label="Sidebar"
    >
      <div>
        <div className="">
          <div className="mb-5 list-none">
            <span className="text-2xs mb-1 pl-1 font-bold uppercase text-gray-800">{account?.name}</span>
            {companyItems.map((item, index) => {
              const path = `/${account?.id}${item.path}`;
              return (
                <div key={index} className=" w-full">
                  {item.visible && (
                    <NavLink
                      to={path}
                      className={`flex h-[28px] w-full cursor-pointer items-center justify-start gap-0.5 rounded-lg px-2.5 font-bold text-gray-600 ${
                        item.upcomingFeature ? 'cursor-not-allowed' : 'hover:bg-gray-50'
                      } ${location.pathname === path ? 'bg-white font-bold text-blue-500' : ''}`}
                      onClick={(e) => (item.upcomingFeature ? e.preventDefault() : null)}
                      style={item.upcomingFeature ? { cursor: 'not-allowed' } : {}}
                    >
                      <div
                        className={`mr-2 flex items-center gap-2 ${location.pathname === path ? 'text-gray-800' : ''}`}
                      >
                        {location.pathname === path ? item.filledIcon : item.icon}
                      </div>
                      <div className="flex items-center justify-center gap-2">
                        <span
                          className={`text-xms text-spray font-semibold ${
                            location.pathname === path ? 'text-spray font-medium' : 'text-spray font-medium'
                          }`}
                        >
                          {item.name}
                        </span>
                        {item.upcomingFeature && (
                          <span className="text-2xs inline-flex items-center rounded-md bg-blue-50 px-1 py-0.5 font-bold text-blue-700 ring-1 ring-inset ring-blue-600/20">
                            Coming soon
                          </span>
                        )}
                      </div>
                    </NavLink>
                  )}
                </div>
              );
            })}
          </div>

          <div>
            <div className="w-full">
              <Listbox value={currentAssistant} onChange={handleSelectChange}>
                {({ open }) => (
                  <>
                    <div className="relative mt-2">
                      <Listbox.Button className="text-2xs mb-3 flex w-full items-center justify-start pl-1 font-bold uppercase text-gray-800">
                        <span className="block truncate">{currentAssistant.name}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <HiChevronUpDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-transparent absolute z-10 max-h-60 w-full cursor-pointer overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {assistants.map((assistant) => (
                            <Listbox.Option
                              key={assistant.id}
                              className={({ active }) =>
                                `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                }`
                              }
                              value={assistant}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                    {assistant.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                        active ? 'text-white' : 'text-indigo-600'
                                      }`}
                                    >
                                      <IoCheckmarkSharp className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>

          <div>
            {menuItems.map((item, index) => {
              const path = `/${account?.id}/${currentAssistant.id}${item.path}`;
              return (
                <div key={index} className="w-full">
                  <NavLink
                    to={path}
                    className={` flex h-[28px] w-full cursor-pointer items-center justify-start gap-0.5 rounded-lg px-2.5 font-bold text-gray-600 ${
                      item.upcomingFeature ? 'cursor-not-allowed' : 'hover:bg-gray-50'
                    } ${location.pathname === path ? 'bg-white font-bold text-blue-500' : ''}`}
                    onClick={(e) => (item.upcomingFeature ? e.preventDefault() : null)}
                    style={item.upcomingFeature ? { cursor: 'not-allowed' } : {}}
                  >
                    <div
                      className={`mr-2 flex items-center gap-2 ${location.pathname === path ? 'text-gray-800' : ''}`}
                    >
                      {location.pathname === path ? item.filledIcon : item.icon}
                    </div>
                    <div className="flex items-center justify-center gap-2">
                      <span
                        className={`text-xms text-spray font-semibold ${
                          location.pathname === path ? 'text-spray font-medium' : 'text-spray font-medium'
                        }`}
                      >
                        {item.name}
                      </span>
                      {item.upcomingFeature && (
                        <span className="text-2xs inline-flex items-center rounded-md bg-blue-50 px-1 py-0.5 font-bold text-blue-700 ring-1 ring-inset ring-blue-600/20">
                          Coming soon
                        </span>
                      )}
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ProfileSettingsDropdown />
    </div>
  );
};

export default MenuSidebar;
