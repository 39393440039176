const FaLocationCrosshairsCrossed = ({ className, style, fill = 'black' }) => {
  return (
    <svg
      className={className}
      size={style}
      width="16"
      height="16"
      viewBox="0 0 207 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103 3C109.914 3 115.5 8.58594 115.5 15.5V29.0547C146.906 34.2891 171.711 59.0938 176.945 90.5H190.5C197.414 90.5 203 96.0859 203 103C203 109.914 197.414 115.5 190.5 115.5H176.945C171.711 146.906 146.906 171.711 115.5 176.945V190.5C115.5 197.414 109.914 203 103 203C96.0859 203 90.5 197.414 90.5 190.5V176.945C59.0938 171.711 34.2891 146.906 29.0547 115.5H15.5C8.58594 115.5 3 109.914 3 103C3 96.0859 8.58594 90.5 15.5 90.5H29.0547C34.2891 59.0938 59.0938 34.2891 90.5 29.0547V15.5C90.5 8.58594 96.0859 3 103 3ZM53 103C53 116.261 58.2678 128.979 67.6447 138.355C77.0215 147.732 89.7392 153 103 153C116.261 153 128.979 147.732 138.355 138.355C147.732 128.979 153 116.261 153 103C153 89.7392 147.732 77.0215 138.355 67.6447C128.979 58.2678 116.261 53 103 53C89.7392 53 77.0215 58.2678 67.6447 67.6447C58.2678 77.0215 53 89.7392 53 103ZM103 71.75C111.288 71.75 119.237 75.0424 125.097 80.9029C130.958 86.7634 134.25 94.712 134.25 103C134.25 111.288 130.958 119.237 125.097 125.097C119.237 130.958 111.288 134.25 103 134.25C94.712 134.25 86.7634 130.958 80.9029 125.097C75.0424 119.237 71.75 111.288 71.75 103C71.75 94.712 75.0424 86.7634 80.9029 80.9029C86.7634 75.0424 94.712 71.75 103 71.75Z"
        fill={fill}
      />
      <rect
        x="-0.727922"
        y="174.372"
        width="247.629"
        height="47.0352"
        rx="23.5176"
        transform="rotate(-45 -0.727922 174.372)"
        fill={fill}
        stroke="white"
        strokeWidth="18"
      />
    </svg>
  );
};

export default FaLocationCrosshairsCrossed;
