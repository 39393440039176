import { useMemo } from 'react';

import { motion } from 'framer-motion';
import './AnimatedBackground.scss';

const smallCount = 5;
const defaultCount = 15;
const bigCount = 30;

// Inspired by https://codeishare.com/how-to-create-a-music-wave-animation-using-html-and-css/
export const AnimatedBackground = ({ percent }: { percent: number }) => {
  const count = useMemo(() => {
    if (window.innerWidth > 1024) {
      return bigCount;
    } else if (window.innerWidth > 640) {
      return defaultCount;
    } else {
      return smallCount;
    }
  }, []);

  return (
    <motion.div
      key="gradient"
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={{ duration: 0.5 }}
      className="absolute bottom-0 w-full bg-gradient-to-t from-blue-400 transition-all duration-1000 motion-reduce:hidden"
      style={{
        height: `${percent}%`,
      }}
    >
      <div className="maskGradient absolute bottom-0 z-10 h-full w-full animate-pulse transition-all duration-1000">
        <div className="movingGradient z-10 h-full w-full bg-blue-400" />
      </div>
      <div className="music_wave">
        {[...Array(count)].map((_, i) => (
          <div className="wave w-8 origin-bottom blur-2xl xl:w-14 xl:blur-3xl" key={i}></div>
        ))}
      </div>
    </motion.div>
  );
};
