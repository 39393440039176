import React, { useState } from 'react';

import { Input, Modal, message } from 'antd';
import { sendPasswordResetEmail } from 'firebase/auth';

import { auth } from '../firebase';

type PasswordResetModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

type FirebaseError = {
  code: string;
  message: string;
};

const PasswordResetModal: React.FC<PasswordResetModalProps> = ({ isOpen, closeModal }) => {
  const [email, setEmail] = useState('');

  const handleOk = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      message.success('Ett e-postmeddelande för återställning av lösenord har skickats.');
      closeModal();
    } catch (error) {
      const firebaseError = error as FirebaseError;
      console.error('Error sending password reset email', firebaseError);
      message.error(`Error sending password reset email: ${firebaseError.message}`);
    }
  };

  return (
    <>
      <Modal title="Återställ lösenord" open={isOpen} onOk={handleOk} onCancel={closeModal}>
        <div className="my-2">
          <label className="block text-sm font-medium text-gray-700">E-postadress</label>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} className="mt-1" />
        </div>
      </Modal>
    </>
  );
};

export default PasswordResetModal;
