import { useCallback, useState } from 'react';

import { Button, Collapse, CollapseProps, Dropdown, Form, Input, List, Menu, Tooltip, message } from 'antd';
import { Question } from 'constants/src';
import { collection, doc, updateDoc } from 'firebase/firestore';
import 'flag-icons/css/flag-icons.min.css';
import set from 'lodash/set';
import { PiDotsThreeOutlineFill } from 'react-icons/pi';

import { useApp } from '../../../AppContext';
import { firestore } from '../../../firebase';
import SelltoButton from '../../../subcomponents/SelltoButton';

// TODO: Refactor this so we don't have so much duplication

// We add the isOpen field to control the accordion/collapsible state
type EditorQuestion = Question & { isOpen: boolean };

type QuestionsSectionProps = {
  aiEnabled: boolean;
  selectedLanguages: string[];
};

export const QuestionsSection = ({ selectedLanguages, aiEnabled }: QuestionsSectionProps) => {
  const { assistant, account } = useApp();

  const [customQuestions, setCustomQuestions] = useState<EditorQuestion[]>(
    assistant?.questions.map((q) => ({ ...q, isOpen: false })) || []
  );

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saving, setSaving] = useState(false);

  const addCustomQuestion = () => {
    setCustomQuestions([
      ...customQuestions,
      {
        title: {
          en: '',
          sv: '',
        },
        description: {
          en: '',
          sv: '',
        },
        placeholder: {
          en: '',
          sv: '',
        },
        desiredAnswer: '',
        dealbreaker: '',
        enabled: true,
        isOpen: true,
      },
    ]);
  };

  const updateCustomQuestion = (index: number, path: string, value: string | boolean) => {
    const newCustomQuestions = [...customQuestions];
    set(newCustomQuestions[index], path, value); // Använder lodash 'set' för att uppdatera nested properties

    setCustomQuestions(newCustomQuestions);
    setUnsavedChanges(true);
  };

  const deleteCustomQuestion = (index: number) => {
    const newCustomQuestions = [...customQuestions];
    newCustomQuestions.splice(index, 1);
    setCustomQuestions(newCustomQuestions);
    setUnsavedChanges(true); // Uppdaterar tillståndet för osparade ändringar
  };

  const menu = (index: number) => (
    <Menu>
      <Menu.Item key="0" onClick={() => deleteCustomQuestion(index)}>
        Delete Question
      </Menu.Item>
    </Menu>
  );

  const saveQuestions = useCallback(async () => {
    if (!account || !assistant) {
      console.error('No account/assistant');
      return;
    }

    setSaving(true);

    // Fördröjer sparfunktionen i 2 sekunder
    setTimeout(async () => {
      try {
        const assistantsCollection = collection(firestore, 'accounts', account.id, 'assistants');

        const assistantDocRef = doc(assistantsCollection, assistant.id);

        const updatedAssistant = {
          questions: customQuestions.map(({ isOpen: _isOpen, ...rest }) => rest),
          languages: selectedLanguages,
        };

        await updateDoc(assistantDocRef, updatedAssistant);
        setUnsavedChanges(false);
        message.success('Questions and languages updated successfully');
      } catch (error) {
        message.error('Could not update questions and languages');
        console.error('Error saving questions and languages:', error);
      } finally {
        setSaving(false);
      }
    }, 1500); // 2000 millisekunder = 2 sekunder
  }, [account, assistant, customQuestions, selectedLanguages]);

  return (
    <>
      <div className="mb-10 overflow-hidden rounded-lg border border-gray-200 bg-white">
        <div className="p-4">
          <div className="flex items-center justify-between gap-20">
            <div className="mb-6">
              <span className="text-md mb-0 mt-0 font-bold">Question steps</span>
              <p className="text-xms text-gray-600">
                In this section, you set up the steps that will run in your dialogue. For every question there is a
                required title, an optional description, the desired answer and an optional dealbreaker
              </p>
            </div>
          </div>
          <List
            dataSource={customQuestions}
            renderItem={(item, index) => {
              const items: CollapseProps['items'] = [
                {
                  key: '1',
                  label: `Question ${index + 1} (${item.title.en || item.title.sv || 'Untitled Question'})`,
                  extra: (
                    <>
                      {/* We need to add this span onClick to prevent the
                      Collapse from toggling when we click on the buttons*/}
                      <div className="flex gap-2">
                        {' '}
                        <span
                          className="flex items-center justify-center gap-3"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <Dropdown
                            overlay={menu(index)}
                            // Prevent click through
                            trigger={['click']}
                          >
                            <Button className="border-0 px-4">
                              <PiDotsThreeOutlineFill />
                            </Button>
                          </Dropdown>
                        </span>
                      </div>
                    </>
                  ),
                  children: (
                    <Form.Item className={`${item.enabled ? '' : 'opacity-50'} w-full p-0`}>
                      <form className="bg-white">
                        <div className="px-4 py-2">
                          <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                            <div className="flex items-center justify-start gap-2">
                              <span>Title</span>
                              <Tooltip title="This information is visible to the respondent">
                                <span className="inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  Public
                                </span>
                              </Tooltip>
                            </div>
                            <p className="text-xs font-normal text-gray-600">
                              The title is what the respondent see as the question which they reply to.
                            </p>
                          </label>

                          <div className="mt-2">
                            {selectedLanguages.includes('en') && (
                              <Input
                                id="title"
                                prefix={<span className="flag-icon fi fi-gb rounded-sm" />}
                                showCount
                                className="mb-2"
                                maxLength={150}
                                size="middle"
                                placeholder="Title"
                                value={item.title.en}
                                onChange={(e) => updateCustomQuestion(index, 'title.en', e.target.value)}
                              />
                            )}
                            {selectedLanguages.includes('sv') && (
                              <Input
                                prefix={<span className="flag-icon fi fi-se rounded-sm" />}
                                showCount
                                className="mb-2"
                                maxLength={150}
                                size="middle"
                                placeholder="Titel"
                                value={item.title.sv}
                                onChange={(e) => updateCustomQuestion(index, 'title.sv', e.target.value)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="px-4 py-2">
                          <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                            <div className="flex items-center justify-start gap-2">
                              <span>Description</span>
                              <Tooltip title="This information is visible to the respondent">
                                <span className="inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  Public
                                </span>
                              </Tooltip>
                            </div>

                            <p className="text-xs font-normal text-gray-600">
                              Help the respondent understand how they could reply to the question.
                            </p>
                          </label>

                          <div className="mt-2">
                            {selectedLanguages.includes('en') && (
                              <Input
                                id="description"
                                prefix={<span className="flag-icon fi fi-gb rounded-sm" />}
                                showCount
                                className="mb-2"
                                maxLength={150}
                                size="middle"
                                placeholder="Description"
                                value={item.description.en}
                                onChange={(e) =>
                                  updateCustomQuestion(
                                    index,
                                    // @ts-ignore - little wonky, but works
                                    'description.en',
                                    e.target.value
                                  )
                                }
                              />
                            )}
                            {selectedLanguages.includes('sv') && (
                              <Input
                                prefix={<span className="flag-icon fi fi-se rounded-sm" />}
                                showCount
                                className="mb-2"
                                maxLength={150}
                                size="middle"
                                placeholder="Beskrivning"
                                value={item.description.sv}
                                onChange={(e) =>
                                  updateCustomQuestion(
                                    index,
                                    // @ts-ignore - little wonky, but works
                                    'description.sv',
                                    e.target.value
                                  )
                                }
                              />
                            )}
                          </div>
                        </div>
                        {aiEnabled && (
                          <div className="px-4 py-2">
                            <div className="mb-4">
                              <div className="mb-2">
                                <label
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                  htmlFor="desiredAnswer"
                                >
                                  Desired response
                                  <p className="text-xs font-normal text-gray-600">
                                    Imagine you&apos;re having a friendly conversation with our AI, explaining what
                                    answers you&apos;re looking for, or not looking for, as you would to a friend.
                                    Example: &quot;It&apos;s great if the lead answers...&quot;, &quot;It&apos;s less
                                    okay if...&quot;
                                  </p>
                                </label>
                              </div>
                              <div className="mt-2">
                                <Input.TextArea
                                  autoSize={{
                                    minRows: 3,
                                    maxRows: 8,
                                  }}
                                  size="middle"
                                  placeholder="Desired Reply"
                                  value={item.desiredAnswer}
                                  onChange={(e) => updateCustomQuestion(index, 'desiredAnswer', e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="">
                              <div className="flex w-full items-center justify-start gap-2">
                                <label
                                  htmlFor="dealbreaker"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Dealbreaker
                                  <p className="text-xs font-normal text-gray-600">
                                    Use with caution. A lead that triggers a dealbreaker is automatically zeroed and
                                    disqualified. This means it will result in a 0 in the matching score.
                                  </p>
                                </label>
                              </div>

                              <div className="mt-2">
                                <Input.TextArea
                                  autoSize={{
                                    minRows: 2,
                                    maxRows: 8,
                                  }}
                                  size="middle"
                                  placeholder="Dealbreaker"
                                  value={item.dealbreaker}
                                  onChange={(e) => updateCustomQuestion(index, 'dealbreaker', e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </form>
                    </Form.Item>
                  ),
                },
              ];
              return (
                <>
                  <Collapse
                    className="mb-2"
                    onChange={(key) => {
                      const isOpen = key.includes('1');
                      updateCustomQuestion(index, 'isOpen', isOpen);
                    }}
                    activeKey={item.isOpen ? '1' : ''}
                    items={items}
                  />
                </>
              );
            }}
          />
        </div>
        <div className="flex w-full items-center justify-between gap-4 border-t bg-white px-4 py-3">
          <Button className="flex items-center justify-center " onClick={addCustomQuestion}>
            <span>+ Add step</span>
          </Button>
          <div className="flex items-center justify-center gap-4">
            {unsavedChanges && <span className="text-xs text-gray-500">Remember to save your changes</span>}
            <SelltoButton onClick={saveQuestions} disabled={!unsavedChanges} loading={saving} type="black">
              Save
            </SelltoButton>
          </div>
        </div>
      </div>
    </>
  );
};
