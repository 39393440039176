import { useEffect, useState } from 'react';

import { Flex, Spinner, Text } from '@chakra-ui/react';
import { message } from 'antd';
import { Account, Assistant, User } from 'constants/src';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { Timestamp, addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import { auth, firestore } from './firebase';
// @ts-ignore - We need to add this here to get svg to work

type LoadingOnboardingProps = {
  onComplete: () => void;
};

const messages = [
  'Setting up your account..',
  'Communicating with AI Servers..',
  'Finalizing account details..',
  'Onboarding modal loading..',
  'Everything loaded successfully..',
  'Finalizing..',
];

const LoadingOnboarding = ({ onComplete }: LoadingOnboardingProps) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (currentMessageIndex < messages.length - 1) {
      const timer = setTimeout(() => {
        setCurrentMessageIndex((prevIndex) => prevIndex + 1);
      }, 2000);
      return () => clearTimeout(timer);
    } else if (currentMessageIndex === messages.length - 1 && !isComplete) {
      setIsComplete(true);
      const completionTimer = setTimeout(() => {
        onComplete();
      }, 2000);
      return () => clearTimeout(completionTimer);
    }
  }, [currentMessageIndex, isComplete, onComplete]);

  return (
    <Flex width="100vw" height="100vh" alignItems="center" justifyContent="center" flexDirection="column">
      <Spinner
        thickness="3px"
        speed="0.63s"
        emptyColor="#fafafa"
        color="blue-500"
        size="xl"
        width="50px"
        height="50px"
      />
      <Text style={{ fontFamily: "'Sora-Variable', sans-serif" }} className="mt-6 text-2xl font-semibold">
        {messages[currentMessageIndex]}
      </Text>
    </Flex>
  );
};

const CreateAccount = () => {
  const navigate = useNavigate();

  const [loadingOnboarding, setLoadingOnboarding] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [orgName, setCompanyName] = useState('');
  const [onboardingComplete, setOnboardingComplete] = useState(false);

  useEffect(() => {
    if (onboardingComplete) {
      navigate('/bots');
    }
  }, [onboardingComplete, navigate]);

  useEffect(() => {
    if (loadingOnboarding) {
      const timer = setTimeout(
        () => {
          setOnboardingComplete(true);
        },
        messages.length * 2000 + 2000
      ); // Vänta tills alla meddelanden har visats
      return () => clearTimeout(timer);
    }
  }, [loadingOnboarding]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (accessCode !== email + '2023') {
      message.error('The access code is incorrect.');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await sendEmailVerification(user);

      try {
        setLoadingOnboarding(true); // Start onboarding process
        // Stop all other actions until onboarding is complete
        while (loadingOnboarding) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        const newAccount: Account = {
          name: orgName,
          members: [user.uid],
          onboardingComplete: false,
          createdAt: Timestamp.now(),
          assistants: [],
        };
        const accountDoc = await addDoc(collection(firestore, 'accounts'), newAccount);

        const assistantsRef = collection(accountDoc, 'assistants');

        const newAssistant: Assistant = {
          name: 'My first assistant',
          information:
            "Replace the following text with information about your business:\n\nThe amount of cheap marketing tricks has made lead generation tougher than ever before - leads simply won't respond and surely they do not leave out their contact information just because they're promised a PDF containing 30 pages of so-called useful content for their business. \"We needed something that could help us achieve the full circuit of leads management. Sellto AI became the last piece\" .. And once that wall is broken, the even bigger issue becomes obvious: Lack of quality, inconsistency and hours of demo-meetings with customers one can't or won't do business with. Sellto AI solves these issues by offering smart forms flows made specifically for the modern sales team. Different from traditional web forms, Sellto AI is built up by a sequence of questions each containing the Question, a Desired Response and a Dealbreaker - all of which help calculating an instant matching score and an organic explanation response, boosting the potential lead's confidence by telling exactly why the product they are offering is a great match with the lead's current situation and needs. Incentives created by truthful and relevant responses make it easier for leads to confidently provide their contact details and submit their genuine interest. If you are in sales or marketing - using Sellto is a no-brainer. Using Sellto AI with Pipedrive allows to seamlessly push any lead into your CRM, with just one simple click! Welcome to the \"Happy-Path\" to qualified leads.",
          enabled: true,
          createdAt: Timestamp.now(),
          powerups: {},
          theme: 'default',
          welcome: {
            title: {
              en: 'Hi there!',
              sv: 'Hej!',
            },
            buttonText: {
              en: 'Start conversation',
              sv: 'Starta konversation',
            },
          },
          afterSubmit: {
            title: {
              en: 'Thank you for your time!',
              sv: 'Tack för din tid!',
            },
            description: {
              en: 'We will get back to you shortly.',
              sv: 'Vi återkommer inom kort.',
            },
            buttonText: {
              en: 'Close',
              sv: 'Stäng',
            },
            action: 'close',
            url: `www.sellto.ai/`,
          },
          defaultResult: {
            title: {
              en: 'Thanks for answering',
              sv: 'Tack för dina svar',
            },
            description: {
              en: 'Please leave your contact details and we will get back to you shortly.',
              sv: 'Vänligen lämna dina kontaktuppgifter så återkommer vi inom kort.',
            },
            buttonText: {
              en: 'Proceed',
              sv: 'Vidare',
            },
            action: 'proceed',
            url: `www.sellto.ai/`,
          },
          companySizes: [],
          countries: [],
          excludeIndustries: [],
          industries: [],
          presetQuestions: {
            1: false,
            2: false,
            3: false,
          },
          questions: [
            {
              dealbreaker: 'If the lead is very negative to AI, this is a no-go.',
              description: {
                en: "This question aims to explore your organization's stance on incorporating AI technology into the sales process.",
                sv: 'Denna fråga syftar till att utforska din organisations inställning till att införliva AI-teknologi i säljprocessen.',
              },
              desiredAnswer:
                "A positive attitude towards AI is preferred, as our service is built on its use! We anticipate a great fit with businesses of all kinds, not solely businesses(B2B) since Sellto AI can also be used when communicating with consumers. What's important is that the lead has potential in buying their product. And are targeting buyers",
              title: {
                en: 'What type of business, sector and industry are your customers in?',
                sv: 'Vilken typ av verksamhet, sektor och industri befinner sig era kunder inom?',
              },
              enabled: true,
              placeholder: {
                en: '',
                sv: '',
              },
            },
          ],
          scoreThresholds: [],
          languages: ['en'],
          aiEnabled: false,
          collectContact: true,
        };
        // We do this so we don't have to hassle with creating assistants
        // later on or having on existing assistants
        addDoc(assistantsRef, newAssistant);

        const newUser: Omit<User, 'id'> = {
          email,
          firstName,
          lastName,
          accountId: accountDoc.id,
          createdAt: Timestamp.now(),
          isAdmin: true,
        };

        const userDocRef = doc(collection(firestore, 'users'), user.uid);
        await setDoc(userDocRef, newUser);

        message.success('Account created successfully, please continue onboarding');

        setLoadingOnboarding(true); // Starta onboarding
      } catch (error) {
        console.error('Error creating user:', error);
        message.error(error instanceof Error ? error.message : 'Could not create account');
      }
    } catch (error) {
      if (error && typeof error === 'object' && 'code' in error && error.code === 'auth/email-already-in-use') {
        message.error('This email is already in use.');
      } else {
        message.error('An error occurred. Please try again.');
      }
    }
  };

  return (
    <section className="bg-white">
      {loadingOnboarding && <LoadingOnboarding onComplete={() => setOnboardingComplete(true)} />}
      <div className="flex min-h-screen items-center justify-center">
        <div className="relative flex min-h-screen w-1/2 items-center justify-center space-y-8 lg:w-1/2">
          <img
            className="absolute left-10 top-10 mx-auto h-8 w-auto"
            src="https://uploads-ssl.webflow.com/651405aa325a21e5721c392d/65249447a54c89915812f134_icon-sellto.svg"
            alt="Sellto Logo"
          />
          <div className="ml-auto mr-auto max-w-xs">
            <div>
              <div className="w-full text-left">
                <h2 className="mb-3 mt-6 text-3xl font-semibold text-gray-900">Create your new Account</h2>
                <p className="text-gray-500">
                  Complete the form to set up your Sellto bot account. An invite code is required. Need one? Contact us:
                  info@sellto.ai
                </p>
              </div>
            </div>

            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px shadow-sm">
                <div className="flex">
                  {/* First Name */}
                  <div>
                    <label htmlFor="first-name" className="sr-only">
                      First Name
                    </label>
                    <input
                      id="first-name"
                      name="first-name"
                      type="text"
                      autoComplete="given-name"
                      required
                      className="relative block w-full appearance-none rounded-none border border-gray-200 px-3 py-2 text-gray-700 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  {/* Last Name */}
                  <div>
                    <label htmlFor="last-name" className="sr-only">
                      Last Name
                    </label>
                    <input
                      id="last-name"
                      name="last-name"
                      type="text"
                      autoComplete="family-name"
                      required
                      className="relative block w-full appearance-none rounded-none border border-gray-200 px-3 py-2 text-gray-700 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                {/* Company Name */}
                <div>
                  <label htmlFor="email" className="sr-only">
                    Company name
                  </label>
                  <input
                    id="company-name"
                    name="company-name"
                    type="text"
                    required
                    className="relative block w-full appearance-none rounded-none border border-gray-200 px-3 py-2 text-gray-700 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    placeholder="Company name"
                    value={orgName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                {/* Email */}
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="relative block w-full appearance-none rounded-none border border-gray-200 px-3 py-2 text-gray-700 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    placeholder="Email address"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                {/* Password */}
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="relative block w-full appearance-none rounded-none border border-gray-200 px-3 py-2 text-gray-700 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {/* Access Code */}
                <div>
                  <label htmlFor="access-code" className="sr-only">
                    Access Code
                  </label>
                  <input
                    id="access-code"
                    name="access-code"
                    type="text"
                    required
                    className="relative block w-full appearance-none border border-gray-200 px-3 py-2 text-gray-700 placeholder-gray-400 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    placeholder="Access Code"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Create Account
                </button>
                <p className="mt-2 text-center text-sm text-gray-600">
                  Do you already have an account?{' '}
                  <a href="/login" className="font-medium text-blue-600 hover:text-blue-500">
                    Login here
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
        <div
          className="hidden h-screen lg:block lg:w-1/2"
          style={{
            backgroundImage:
              'url(https://images.unsplash.com/photo-1692037198805-c7421032c5cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3087&q=80)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
      </div>
    </section>
  );
};

export default CreateAccount;
